.matching_game_con {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("/public/images/matchingGameBg2.jpg");
    background-position: center;
    background-size: cover;
}

.mute_btn{
    position: absolute;
    top: 20px;
    right: 20px;
}

.matching_game_con .back_btn {
    position: absolute;
    top: 20px;
    height: 60px;
    width: 80px;
    left: 20px;
    background-image: url("/public/images/back.png");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.matching_game_con .grid_con {
    width: 950px;
    height: 600px !important;
    box-sizing: border-box;
    /* border: 1px solid red; */
    background-image: url("/public/images/matchingGameCloud.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.matching_game_con .grid_con .grid_con_item {
    width: fit-content;
    height: fit-content;
    display: grid;
    grid-gap: 20px;
}

.matching_game_con .grid_con .two_cards {
    grid-template-columns: 120px 120px;
}

.matching_game_con .grid_con .six_cards {
    grid-template-columns: 120px 120px 120px;
}

.matching_game_con .grid_con .eight_cards {
    grid-template-columns: 120px 120px 120px 120px;
}

.matching_game_con .grid_con .ten_cards {
    grid-template-columns: 120px 120px 120px 120px 120px !important;
}

.matching_game_con .grid_con .fourteen_cards {
    grid-template-columns: 120px 120px 120px 120px 120px;
}

.matching_game_con .grid_con .sixteen_cards {
    grid-template-columns: 120px 120px 120px 120px;

}

.matching_game_con .grid_con .card {
    width: 120px;
    height: 119px;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    transform: rotateY(180deg);
    display: flex;
    border-radius: 10px;
    align-items: center;
    /* Center the text/content vertically */
    justify-content: center;
}

.matching_game_con .grid_con .card .relative_img {
    position: absolute;
    top: 0px;
    width: 100%;
    transform: rotateY(-180deg);
    background-image: url("/public/images/matchingCardBg.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}



.matching_game_con .grid_con .openCard {
    transform: rotateY(0deg);

}

.matching_game_con .grid_con .closeCard {
    transform: rotateY(180deg);

}

.matching_game_con .grid_con .card .card-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
}

.matching_game_con .grid_con .card-inner {}

.matching_game_con .grid_con .card-inner .back-part {
    color: red !important;
    position: absolute;
    top: 0px;
}


.matching_game_con .grid_con .card .card-front {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.matching_game_con .grid_con .card .card-front img {
    width: 100%;
    cursor: pointer;
    height: 100%;
}