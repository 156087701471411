.mainSection {
  background-color: #ddf1ff;
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
}

.roundBox {
  background-color: white;
  width: 80px;
  height: 80px;
  font-size: 14px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FoodProfile {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

/* getting ready section */

.contentforphone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.upper_row {
  width: 100%;
  height: 40%;
  display: flex;
  gap: 10px;
}

.drag_and_drop {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.drag_heading {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.drag_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d6eeff;
  border: 2px dashed rgba(61, 177, 255, 1);
  border-radius: 24px;
  color: rgba(61, 177, 255, 1);
  font-family: Lexend;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.lower_row {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.heading_of_drag {
  width: 100%;
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.lowers_lower {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 10px;
}

.drag_and_drop_low {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.img_of_drag {
  height: 80%;
  width: 100%;
}

.img_inner {
  width: 100%;
  height: 100%;
  border-radius: 24px 24px 0px 0px;
}

.img_heading {
  height: 30%;
  width: 100%;
  border-radius: 0px 0px 24px 24px;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background: rgba(231, 243, 251, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Cards {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 8px;
  padding: 20px;
  border-radius: 48px;
}
.Cards2 {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 8px;
  padding: 20px;
  border-radius: 48px;
  height: 75vh;
}
.Cards3 {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 8px;
  padding: 20px;
  border-radius: 48px;
  height: 90vh;
}

.Cards1 {
  width: 100%;
  height: 85%;
  background-color: white;
  padding: 20px;
  border-radius: 48px;
}

.imageHeading {
  font-size: 16px;
  font-weight: 400;
  color: #28303a;
}
.CardBody {
  background-color: #e7f3fb;
  border-radius: 0 0 25px 25px;
  color: 428303A;
  /* height: 100px; */
}
.ImageCustom {
  border-radius: 25px 25px 0 0;
  height: 100px;
}
.spin {
  border: 10px solid white;
  border-radius: 100%;
  padding: 25px;
}
.spinHeading {
  font-size: 16px;
  color: #28303a;
  font-weight: 700;
}
.spinHeading1 {
  font-size: 16px;
  font-weight: 400;
  color: #28303a;
}
.mxplayer {
  font-size: 50px;
  color: #52abeb;
}
.ButtonYes {
  background-color: green;
  font-size: 24px;
  color: white;
  font-weight: 700;
  padding: 2px 50px;
  border: none;
  border-radius: 10px;
}
.ButtonNo {
  background-color: red;
  color: white;
  font-size: 24px;
  font-weight: 700;
  padding: 5px 50px;
  border: none;
  border-radius: 10px;
}
.timeHeading {
  font-size: 12px;
  font-weight: 700px;
  color: #79b6e1;
}
.ProfileLogo {
  width: 30px;
  height: 30px;
}
.Previous {
  background-color: white;
}
.Previous1 {
  background-color: #79b6e1;
}
.BackArrow {
  font-size: 16px;
  font-weight: 700;
  color: #28303a;
}
.ShapesHeading {
  font-size: 16px;
  font-weight: 700;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
}
.ShapesHeading1 {
  font-size: 12px;
  font-weight: 700;
  color: #28303a;
  background-color: white;
  border-radius: 24px;
  cursor: pointer;
  padding: 5px 10px;
}
.HeaderProfile {
  background-color: #79b6e1;
}
.Buttonpng {
  width: 70%;
}
.Sequences {
  font-size: 24px;
  font-weight: 900;
}
.ArrowIcon {
  color: white;
  font-size: 20px;
  padding: 3px 10px;
  background-color: #909ea6;
  border-radius: 24px;
}

@media only screen and (max-width: 767px) {
  .Cards2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px;
    height: 40vh;
  }

  .boxContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    align-items: center;
    gap: 1px;
  }
  .roundBox {
    background-color: white;
    width: 50px;
    height: 50px;
    font-size: 12px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ArrowIcon {
    color: white;
    font-size: 15px;
    padding: 5px 10px;
    background-color: #909ea6;
    border-radius: 24px;
  }
  .CardBody {
    background-color: #e7f3fb;
    border-radius: 0 0 25px 25px;
  }
}

@media only screen and (max-width: 460px) {

  








    .contentforphone {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
      
      .upper_row {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      
      .drag_and_drop {
        width: 100%;
        height: 25%;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      
      .drag_heading {
        font-family: Lexend;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
      
      .drag_container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #d6eeff;
        border: 2px dashed rgba(61, 177, 255, 1);
        border-radius: 24px;
        color: rgba(61, 177, 255, 1);
        font-family: Lexend;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
      
      .lower_row {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        /* gap: 10px; */
      }
      
      .heading_of_drag {
        width: 100%;
        text-align: center;
        font-family: Lexend;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
      
      .lowers_lower {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        gap: 5px;
      }
      
      .drag_and_drop_low {
        width: 100%;
        height: 25%;
        display: flex;
        flex-direction: column;
      }
      
      .img_of_drag {
        height: 50%;
        width: 100%;
      }
      
      .img_inner {
        width: 100%;
        height: 100%;
        border-radius: 24px 24px 0px 0px;
      }
      
      .img_heading {
        height: 30%;
        width: 100%;
        border-radius: 0px 0px 24px 24px;
        font-family: Lexend;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        background: rgba(231, 243, 251, 1);
        display: flex;
        justify-content: center;
        align-items: center;
      }












  .Cards3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 12px !important;
    height: 100vh;
  }
  .Cards2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 12px !important;
    height: 100vh;
  }
  .Cards1 {
    width: 100%;
    height: 85%;
    background-color: white;
    padding: 20px;
    border-radius: 48px;
  }

  .boxContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    align-items: center;
  }
  .roundBox {
    background-color: white;
    width: 48px;
    height: 48px;
    font-size: 11px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ButtonYes {
    background-color: green;
    font-size: 20px;
    color: white;
    font-weight: 700;
    padding: 2px 40px;
    border: none;
    border-radius: 10px;
  }
  .ButtonNo {
    background-color: rgb(255, 7, 7);
    font-size: 20px;
    color: white;
    font-weight: 700;
    padding: 2px 40px;
    border: none;
    border-radius: 10px;
  }
}

