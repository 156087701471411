.bgImg {
    overflow: hidden;
    margin: auto;
    background: url('/public/images/matchingGameBg2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
}
.pdf_container {
    overflow: hidden;
    margin: auto;
}

.page{
    width: 900px !important;
    height: 900px !important;
}

