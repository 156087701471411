.ModalContainer .modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 0 15px;
  z-index: 1000;
}

.ModalContainer .show {
  display: block;
}

.ModalContainer .inner {
  width: 70%;
  max-width: 698px;
  /* height: 70%; */

  max-height: 590px;
  padding: 24px;
  background: white;
  border-radius: 32px 32px 32px 32px;
  z-index: 2;
  position: relative;
  overflow: auto;
  /* border: 2px solid red; */

  .ModalContainer .inner &::-webkit-scrollbar {
    width: 0px !important
  }
}

.ModalContainer .inner_modal {
  width: 100%;
  position: absolute;
  z-index: 99;
}

@media (max-width:604px) {


  .ModalContainer .inner {
    width: 100%;

  }




}