.sequence_main_con {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(221, 241, 255, 1);
}

.sequence_main_con .header {
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 0px 30px;

}

.sequence_main_con .reset_text {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(121, 182, 225, 1);
    cursor: pointer;
}

.sequence_main_con .main_body {
    height: calc(100vh - 52px);
    background-color: white;
    margin-top: 13px;
    border-radius: 48px 48px 0px 0px;
    overflow-y: auto;
}

.sequence_main_con .main_body .body_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 70px;
}

.sequence_main_con .main_body .body_header .head {
    font-family: Lexend;
    font-size: 24px;
    font-weight: 700;
    background: none;
    border-radius: 60px 60px 0px 0px;
}



@media (max-width:600px) {

    .sequence_main_con .main_body .body_header {
        padding: 25px 15px !important;
    }
}









.headerofmodal {
    padding-bottom: 50px;
}

.EditFolderHeading {
    font-size: 20px;
    font-weight: 900;
    color: #28303A;
    padding: 20px 0px;
}

.AbcdImg {
    border-radius: 48px;
    width: 164px;
    height: 130px;
}

.Label {
    font-size: 16px;
    color: #28303A;
    font-weight: 700;
}

.closebutton {
    background-color:
        #DDF1FF;
    width: 48%;
    border-radius:
        12px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: #79B6E1;
}

.sendbutton {
    background-color:
        #79B6E1;
    width: 48%;
    border-radius:
        12px;
    height: 56px;
    border: none;
    font-size: 16px;
    color: white;
    font-weight: 700;
}

.DeleteHeading {
    color: red;
    font-size: 16px;
    font-weight: 700;
    padding-top: 20px;
}

.buttonMaindiv {
    /* padding-top: 10px; */
}

@media (max-width:1366px) {
    .buttonMaindiv {
        /* padding-top: 100px; */
    }
}

@media (max-width:600px) {
    .EditFolderHeading {
        font-size: 16px;
    }

    .body_header {
        padding: 25px 10px !important;
    }
}

@media (max-width:432px) {
    .buttonMaindiv {
        /* padding-top: 68px; */
    }
}