.MainSentences{
    .reset {
        font-size: 16px;
        font-weight: 900;
        color: #79b6e1;
      }
.editBackground {
    background-color: #E7F3FB;
    width: 100%;
    height: 100vh;
    overflow: hidden;
 
  }
  .mainheader{
    width: 100%;
    height: 70px;
    /* border: 1px solid black; */
    
  }
  .edithead {
    background-color: white;
    position: sticky;
  }
  .BackArrow{
    font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #28303A;
  }
  .editsection1 {
    /* border: 1px solid red; */
    background-color: white;
    border-radius: 48px 48px 0 0;
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
  .homeHeader{
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    /* border: 2px solid red; */
  }
  .Homeheading {
    font-family: Lexend;
   font-size: 24px;
   font-weight: 700;
   line-height: 32px;
   letter-spacing: 0em;
   text-align: left;
   margin-left: 12px;
    color: #28303a;
   
   }
   .ContentHomeIcon3 {
    background-color: #7a93a8 !important;
    padding: 6px 8px;
    font-weight: 900;
    color: white;
    font-size: 18px;
    border-radius: 10px;
  }
  .NewFolder {
    font-size: 16px;
    font-weight: 700;
    color: #79b6e1;
    cursor: pointer;
  }
  .headerofmodal {
    padding-bottom: 20px;
  }
  .EditFolderHeading {
    font-size: 20px;
    font-weight: 900;
    color: #28303a;
    cursor: pointer;
  }
 

  .EditModalBtn{
    padding-top: 48px;
 }

}
.closebutton {
    background-color: #ddf1ff;
    width: 48%;
    border-radius: 12px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: #79b6e1;
  }
  .sendbutton {
    background-color: #79b6e1;
    width: 48%;
    border-radius: 12px;
    height: 56px;
    border: none;
    font-size: 16px;
    color: white;
    font-weight: 700;
  }
  .DeleteHeading {
    color: red;
    font-size: 16px;
    font-weight: 700;
    padding-top: 20px;
  }
  .AbcdImg {
    border-radius: 48px;
    width: 164px;
    height: 130px;
  }
  .longCard_con {
    display: flex;
    align-items: center;
    padding: 0px 40px;
  
    .content_con {
      width: 100%;
      display: flex;
      /* flex-direction: row; */
      align-items: center;
  
      .dot_img {
        width: 20px;
        height: 20px;
      }
  
      .content_text {
        /* background-color: rgba(231, 243, 251, 1); */
        width: 100% !important;
        border-radius: 16px;
        margin-right: 10px;
        flex-direction: column;
        gap: 10px;

       .TableStyle{
        width: 100%;
       display: flex;
        background-color: rgba(231, 243, 251, 1);
        border-radius: 16px;
        margin-right: 10px;
       justify-content: space-between;
       align-items: center;
       
       }
        .text {
          font-family: Lexend;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
  
        .imgeStyle {
          width: 72px;
          height: 72px;
          border-radius: 16px 0px 0px 16px;
        }
      }
    }
  
    .actions_btn {
      /* border: 1px solid red; */
  
      .ArrowIcon {
        color: white;
        font-size: 20px;
        padding: 6px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 2px 2px 4px 0px rgba(203, 217, 224, 1) inset;
        width: 56px;
        height: 40px;
        font-size: 20px;
        background-color: #909ea6;
      }
  
      @media (max-width: 600px) {
        .ArrowIcon {
          width: 40px;
          height: 30px;
          font-size: 16px;
        }
      }
    }
  
    .btnCon {
      display: flex;
    }
  
    @media only screen and (max-width: 600px) {
      .btnCon {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
    }
  }