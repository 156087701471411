.tracing_game_main_con {
    height: 100vh;
    background: url("/public/images/spellingpractice/background_main.png") no-repeat;
    background-position: center;
    background-size: cover;
    background-size: 100% 100%;
    padding-top: 20px;
}

.main_div_spelling {
    height: 100vh;
    background: url("/public/images/spellingpractice/background_main.png") no-repeat;
    background-position: center;
    background-size: cover;
    background-size: 100% 100%;
    padding-top: 20px;
}

.back_btn {
    height: 40px;
    width: 50px;
    background-image: url("/public/images/back.png");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 20px;
}

.message_box {
    width: 30%;
    font-style: oblique;
    margin-left: 35%;
    padding: 1%;
    /* margin-top: 2%; */
    font-size: x-large;
    border-radius: 15px;
    background-color: #f9f9f9;
    text-align: center;
    /* vertical-align: middle; */
    box-shadow: 2px 2px 12px gray;
}


.container_of_given_word {
    display: flex;
    justify-content: center;
    /* padding: 2rem 0 1rem 0; */
    line-height: normal;
    font-size: 7rem;
    font-weight: 900;
    color: #55c0b3;

    /* text-shadow:
        -2px -2px 0 rgb(255, 0, 0),  
         2px -2px 0 rgb(0, 0, 0),
        -2px  2px 0 rgb(123, 255, 0),
         2px  2px 0 rgb(255, 0, 106); */

    letter-spacing: 0.2rem;
}

.input_box_container {
    display: flex;
    justify-content: center;
}

.input_box {
    border-radius: 30px;
    height: 3.62rem;
    width: 20rem;
    padding: 0 1rem;
    font-size: x-large;
    background-color: rgb(255, 208, 0);
    border: 3px solid orange;
    color: blue;
}

.input_box:focus {
    outline: none;

}


.keyboard_container {
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    bottom: 0;

}

.keyboard {
    width: 80%;
    background-color: aqua;
    border-radius: 25px 25px 0 0;
    padding: 2rem;
}

.keys_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.row_1 {
    display: flex;
    gap: 0.5rem;
}

.key {
    background: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    padding: 0.3rem 0;
}




.spelling_main_con {
    height: 100vh;
    box-sizing: border-box;
    padding: 30px 50px;
    background-image: url("/public/images/spellingpractice/background_main.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .heading {
        font-size: 4rem;
        font-weight: 700;
        color: #7f6047;
    }

    .spelling_back_btn {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    .img_con {
        height: 300px;
        display: flex;
        justify-content: center;
    }

    .option_con {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

    }

    .option_item {
        border: 2px solid red;
        padding: 20px;
        cursor: pointer;
        border-radius: 10px;
        font-size: 25px;
        font-weight: 700;
        letter-spacing: 7px;
    }
}

.spellingPracticeShuffle {
    animation: myAnimation 0.5s linear
}

@keyframes myAnimation {
    0% {
        transform: translateX(10px);
    }

    15% {
        transform: translateX(-10px);
    }

    30% {
        transform: translateX(10px);
    }

    45% {
        transform: translateX(-10px);
    }

    60% {
        transform: translateX(10px);
    }

    75% {
        transform: translateX(-10px);
    }

    90% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(-10px);
    }

}