.Content {
  background-color: white;
  border-radius: 48px 0 0 0;
  height: 100%;
  padding: 10px 40px;
  overflow: auto;

  .ContentHeadings {
    color: #28303a;
    font-size: 24px;
    font-weight: 700;
  }

  .tab_btn {
    border-radius: 10px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    padding: 10px 25px;
    margin-bottom: 20px !important;
  }

  .tab_active {
    /* border-bottom: 4px solid rgba(121, 182, 225, 1);
      background-color: rgba(121, 182, 225, 0.322); */
    /* border: 1px solid rgb(3, 48, 80) !important; */
    background: rgb(53, 159, 235);
    /* box-shadow: 0 0 10px 2px rgba(121, 182, 225, 0.5); */
    color: white;
  }

  /* .tab_btn:hover{
      background-color: rgba(121, 182, 225, 0.068);
    }
    .tab_btn:hover{
      background-color: rgba(121, 182, 225, 0.068);
    } */
  .about {
    color: #28303a;
    font-size: 24px;
    font-weight: 700;
  }

  .current_active {
    text-transform: capitalize;
  }

  .top_content_con {
    background-color: rgba(121, 182, 225, 0.123);
    padding: 30px;
    border-radius: 20px;
  }


  @media (max-width: 600px) {
    .ContentHeadings {
      font-size: 20px;
      font-weight: 700;
    }
  }

}