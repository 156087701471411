

.shortCard_con {
    width: 100%;
    min-height: 160px;
    border-radius: 24px;
    border: 0.5px solid rgb(233, 228, 228);
}

.shortCard_con img {
    width: 100%;
    height: 83px;
    border-radius: 24px 24px 0px 0px;
    
}

.shortcardText_con {
    padding: 5px;
}

.text_con {
    height: 75px;
    background-color: rgba(231, 243, 251, 1);
    font-family: 'Poppins';
    font-size: 16px;
    border-radius: 0px 0px 24px 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    text-align: center;
    word-wrap: break-word;
}

.text_img {
    height: auto;
    width:100%;
    display: inline-block;
    /* border: 1px solid green; */
    border-radius: 8px ;
    padding: 10px 10px;
    font-size: 20px;
}