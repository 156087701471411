.Contents {
  background-color: white;
  border-radius: 48px 0 0 0;
  height: calc(100vh - 70px);
  padding: 10px 40px;
}
.AboutContents {
}
.ContentHeadings {
  color: #28303a;
  font-size: 24px;
  font-weight: 700;
}
.Profilename {
  color: #28303a;
  font-size: 24px;
  font-weight: 700;
}
.Label {
  font-size: 16px;
  font-weight: 700;
}
.AbcdImg {
  border-radius: 48px;
  width: 164px;
  height: 130px;
}
.UpdateButton {
  background-color: #909ea6;
  padding: 6px 24px;
  border-radius: 24px;
  color: white;
  font-size: 12px;
  font-weight: 900;
  border: none;
}