.Contents {
  background-color: white;
  border-radius: 48px 0 0 0;
  height: calc(100vh - 70px);
  padding: 10px 40px;
  overflow-y: hidden !important;
}
.ContentHeadings {
  color: #28303a;
  font-size: 24px;
  font-weight: 700;
}
.Profilename {
  color: #28303a;
  font-size: 24px;
  font-weight: 700;
}
.Label {
  font-size: 16px;
  font-weight: 700;
}
.UpdateButton {
  background-color: #909ea6;
  padding: 6px 24px;
  border-radius: 24px;
  color: white;
  font-size: 12px;
  font-weight: 900;
  border: none;
}
.UpdateButton1 {
  background-color: #ef3331;
  padding: 6px 24px;
  border-radius: 24px;
  color: white;
  font-size: 12px;
  font-weight: 900;
  border: none;
}

@media (max-width: 479px) {
  .DeleteAndLogOutBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .UpdateBtn {
    display: flex;
    justify-content: center;
    padding-bottom: 20px !important;
  }
}
