.background_img {
  background: url("/public/images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow: hidden;
}
.close_button {
  background: white;
  /* color: white; */
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  font-size: large;
  /* transition-duration: 0.4s; */
}

.close_button:hover {
  background-color: white;
  color: black;
}
.header_of_onboarding {
  width: 100%;
  display: flex;
  justify-content: center;
}

.heading_section_of_onboarding {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small_heading_of_header {
  width: 52px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  word-wrap: break-word;
}

.large_heading_of_header {
  width: 635px;
  font-size: 44px;
  line-height: 48px;
  font-weight: 900;
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.parent_content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.content_section_of_onboarding {
  display: flex;
  width: 660px;
  height: 100%;
  padding: 1rem 0rem;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px 32px 0px 0px;
  background: #fff;
}

.headerofsignin {
  height: 7.88%;
  width: 100%;
  padding: 0px 1rem;
}

.headingofsignin {
  height: 71.42%;
  width: 56.3%;
  padding: 0.5rem 0rem;
  display: flex;
}

.headingtextofsignin {
  height: 60%;
  width: 100.7%;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.backbutton {
  width: 18.32%;
  height: 100.42%;
  padding: 0.1rem 1rem;
  cursor: pointer;
  color: black;
}

.slide_of_onboarding {
  width: 100%;

  height: 92%;
}

.uppersection {
  height: 80%;
  padding: 2.29% 4.58% 6.87% 4.58%;
}

.corousel_of_onboarding {
  width: 100%;
  height: 100%;
}

.corousel_inner_of_onboarding {
  width: 100%;
  height: 100%;
}

.corousel_item_of_onboarding {
  height: 100%;
  width: 100%;
}

.headingofslide {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 3.7%;
}

.corousel_caption_of_onboarding {
  height: 17.6%;
  margin-bottom: 3.7%;
  /* box-sizing: content-box !important; */
}

.heading_of_caption {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.61%;
}

.subheading_of_caption {
  height: 63.15%;
  border-radius: 12px;
  border: 1px solid rgba(144, 158, 166, 0.35);
  width: 100%;
  padding: 0px 15px 0px 15px;
}

.indicators {
  height: 16.59%;
  position: relative;
}

.corousel_indicators_for_signin {
  padding: 0% !important;
  position: absolute !important;
  bottom: 0;
  margin: 0%;
}

.login_section {
  /* height: 20.57%; */
  width: 100%;
  /* padding: 0% 5%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1%;
}
.backButton{

    /* height: 50.75%; */
    padding: 6px;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(121, 182, 225, 1);
    border-radius: 32px ;
    border: 2px solid rgba(121, 182, 225, 1);
    color: white;
  
}
.login_button {
  /* height: 50.75%; */
  padding: 6px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(121, 182, 225, 1);
  border-radius: 12px;
  border: 2px solid rgba(121, 182, 225, 1);
  color: white;
}
.login_button span {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
  text-decoration: none;
}

.new_user_button {
  /* margin-top:4%; */
  height: 43.75%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new_user_button span {
  color: rgba(121, 182, 225, 1);
}

.signin_section {
  /* height: 20.57%; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 80px !important;
  /* border: 2px solid red; */
}

.signinback_button {
  /* height: 50.75%; */
  width: 35%;
  padding: 6px;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  /* padding: 7px; */
  color: rgba(121, 182, 225, 1);
  background: rgba(221, 241, 255, 1);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(221, 241, 255, 1);
}

.signinnext_button {
  height: 50.75%;
  width: 35%;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  /* padding: 7px; */
  color: white;
  background: rgba(121, 182, 225, 1);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(121, 182, 225, 1);
  padding: 6px;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .large_heading_of_header {
    font-size: 40px;
  }

  .heading_of_slide {
    text-align: center;
    font-size: 1rem;
  }

  .headerofsignin {
    height: 7.88%;
    width: 100%;
    padding: 0px 1rem;
  }

  .backbutton {
    font-size: 20px;
    width: 10%;
  }

  .headingofsignin {
    height: 71.42%;
    width: 100%;
    padding: 0.5rem 0rem;
    display: flex;
  }

  .headingtextofsignin {
    font-size: 20px;
  }
}

@media screen and (max-width: 850px) {
  .large_heading_of_header {
    font-size: 40px;
  }

  .heading_of_slide {
    text-align: center;
    font-size: 1rem;
  }

  .headerofsignin {
    height: 7.88%;
    width: 100%;
    padding: 0px 1rem;
  }

  .backbutton {
    font-size: 20px;
    width: 10%;
  }

  .headingofsignin {
    height: 71.42%;
    width: 100%;
    padding: 0.5rem 0rem;
    display: flex;
  }

  .headingtextofsignin {
    font-size: 20px;
  }

  .content_section_of_onboarding {
    width: 90%;
  }
}

@media screen and (max-width: 440px) {
  .large_heading_of_header {
    font-size: 20px;
  }

  .heading_of_slide {
    text-align: center;
    font-size: 1rem;
  }

  .backbutton {
    width: 15%;
    padding: 0rem;
  }

  .headerofsignin {
    height: 7.88%;
    width: 100%;
    padding: 0px 1rem;
  }

  .headingofsignin {
    height: 71.42%;
    width: 100%;
    padding: 0.5rem 0rem;
    display: flex;
  }

  .headingtextofsignin {
    font-size: 14px;
  }

  .content_section_of_onboarding {
    width: 90%;
  }
}
