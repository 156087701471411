.gameContainer {
    width: 100%;
    height: 100vh;
    background: url('/public/images/bg5.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.gameContainer2 {
    width: 100%;
    height: 100vh;
    background: url('/public/images/bg4.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.extra {
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: space-between;
    align-items: start;
    /* border: 1px solid red; */
}

.top {
    width: 100%;
    height: 30vh;
    /* border: 1px solid red; */
    position: relative;
}



.cloudPosition {
    width: 30%;
    height: 100%;
    position: absolute;
    left: 30%;
    /* border: 1px solid black; */
}

.wantCon {
    width: 100%;
    height: 100%;
    background: url('/public/images/cloud.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    /* border: 1px solid red; */
}

.cloudImg {
    position: absolute;
    left: 35%;
    top: 30%;
    /* width: 100px; */
}

.middle {
    width: 100%;
    height: 37vh;
    /* border: 1px solid red; */
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33.33%,1fr )); */
    display: flex;
}

.catCon {
    /* border: 1px solid green; */
    width: 33.33%;
    display: flex;
    justify-content: end;
}

.tableCon {
    /* border: 1px solid green; */
    width: 33.33%;

}

.empty {
    width: 100%;
    height: 20%;
}

.table {
    width: 100%;
    height: 80%;
    /* background: url('/public/images/Plate.png'); */
    background: url('/public/images/basket.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.baloon {
    position: absolute;
    top: 0;
    width: 60%;
}

.blank {
    /* border: 1px solid black; */
    width: 30%;
    display: flex;
    align-items: end;
}

.bottom {
    width: 100%;
    height: 15vh;
    /* border: 1px solid red; */
}

.boxCon {
    width: 60%;
    margin: 0 auto;
    height: 100%;
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
}

.playGame {
    width: 100%;
    height: calc(100vh - 25vh);
}

.playBtn {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 12%;
}

.playText {
    font-size: 3rem;
    margin-left: 25px;
    font-weight: 700;
}

.disableItem {
    opacity: 40% !important;
}

.catImg {
    width: 60%;
}



.floating {
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

.ChooseImg {
    width: auto;
}


.loader {
    width: 100%;
    height: 100vh;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

@-moz-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Firefox */
@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Webkit */
@-ms-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* IE */
@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Opera */

.download {
    padding: 15px 15px 15px 15px;
    text-align: center;
    margin-bottom: 4px;
    font-size: 2rem;
    font-weight: 800;
    border-radius: 5px;
    font-family: calibri;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-animation: blink normal 1.5s infinite ease-in-out;
    /* Firefox */
    -webkit-animation: blink normal 1.5s infinite ease-in-out;
    /* Webkit */
    -ms-animation: blink normal 1.5s infinite ease-in-out;
    /* IE */
    animation: blink normal 1.5s infinite ease-in-out;
    /* Opera */
}


@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0px);
    }
}

.fruitWidthLargeC{
    width: 20% !important;
}
.fruitWidthsmallC{
 width: 20% !important;
}

.fruitWidthLargeD{
    width: 30% !important;
}
.fruitWidthsmallD{
 width: 60% !important;
}


@media (max-width:1260px) {
    .cloudPosition {
        width: 40%;
    }

    .catImg {
        width: 65%;
        height: auto;
    }
    .fruitWidthLargeC{
        width: 12% !important;
    }
    .fruitWidthsmallC{
     width: 20% !important;
    }

}

@media (max-width:1100px) {
    .boxCon {
        width: 70%;
       
    }

}
@media (max-width:1000px) {
    .catImg {
        width: 75%;
        height: auto;
    }

    .catCon {
        width: 50%;
        display: flex;
        justify-content: center;
        padding: 5%;
    }

    .tableCon {
        width: 50%;

    }

    .blank {
        width: 0%;
        height: 0%;
    }
    .cloudPosition {
        width: 50%;
    }

}

@media (max-width:930px) {
    /* .cloudPosition {
        width: 50%;
    } */

}

@media (max-width:900px) {

    .cloudImg {
        top: 25%;
        /* width: 80px; */
    }

    .ChooseImg {
        width: 55%;
    }

    .rightImg {
        width: 15%;
    }
    .boxCon {
        width: 80%;
       
    }
}

@media (max-width:800px) {
    .catImg {
        width: 100%;
        height: auto;
    }
    .cloudImg {
        top: 35%;
       left: 45%;
    }
    .boxCon {
        width: 80%;
       
    }

    .fruitWidthLargeD{
        width: 42% !important;
    }
    .fruitWidthsmallD{
     width: 72% !important;
    }
    /* .cloudImg {
        top: 25%;
    } */

}

@media (max-width:780px) {
    .cloudPosition {
        width: 60%;
        /* left: 20%; */
    }
    .cloudImg {
        left: 35%;
    }

    .catCon {
        padding: 3%;
    }
}


@media (max-width:670px) {
    .cloudImg {
        left: 45%;
        /* width: 80px; */
    }

    .rightImg {
        width: 25%;
    }
    .boxCon {
        width: 80%;
       
    }

    .fruitWidthLargeC{
        width: 20% !important;
    }
    .fruitWidthsmallC{
     width: 25% !important;
    }

    .catCon {
        width: 50%;
        display: flex;
        justify-content: end;
        padding: 5%;
    }

    .tableCon {
        width: 50%;

    }

    .blank {
        width: 0%;
        height: 0%;
    }
}

@media (max-width:600px) {

    .cloudImg {
        left: 45%;
    }
    .boxCon {
        width: 90%;
       
    }
}

@media (max-width:500px) {
    .wantCon {
        background-position: top;
    }

    .cloudPosition {
        width: 90%;
        left: 5%;
    }

    .fruitWidthLargeC{
        width: 15% !important;
    }
    .fruitWidthsmallC{
     width: 20% !important;
    }

    .cloudImg {
        left: 50%;
    }

    .ChooseImg {
        width: 85%;
    }

    .rightImg {
        width: 30%;
    }

}
@media (max-width:450px) {
    .fruitWidthLargeD{
        width: 40% !important;
    }
    .fruitWidthsmallD{
     width: 70% !important;
    }

}


/* for height media */
@media (max-height:660px) {
    .extra {
        width: 100%;
        height: 10vh;
    }

    .cloudImg {
        top: 29% !important;
        left: 25% !important;
        /* width: 50px; */
    }

    .catCon {
        width: 35%;
        display: flex;
        justify-content: end;
        padding: 5%;
    }

    .tableCon {
        width: 35%;

    }

    .blank {
        width: 30%;
        height: 0%;
    }
}


@media (max-height:450px) {
    .cloudImg {
        top: 23% !important;
        left: 16% !important;
        /* width: 50px; */
    }
    .ChooseImg {
        width: 55%;
    }
    .fruitWidthLargeD{
        width: 26% !important;
    }
    .fruitWidthsmallD{
     width: 50% !important;
    }
    .fruitWidthLargeC{
        width: 8% !important;
    }
    .fruitWidthsmallC{
     width: 15% !important;
    }

    .catCon {
        width: 30%;
        display: flex;
        justify-content: end;
        padding: 2%;
    }

    .tableCon {
        width: 40%;

    }

    .blank {
        width: 30%;
        height: 0%;
    }
   
}
@media (max-height:420px) {
  
    .cloudImg {
        top: 22% !important;
        left: 15% !important;
        /* width: 50px; */
    }
}
@media (max-height:390px) {
    .catCon {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: end;
        padding: 1%;
    }

    .tableCon {
        width: 35%;
       
    }
    .cloudImg {
        top: 22% !important;
        left: 17% !important;
        /* width: 50px; */
    }

    .blank {
        width: 30%;
        height: 0%;
    }
}
@media (max-height:370px) {
    
    .cloudImg {
        top: 24% !important;
        left: 13% !important;
        /* width: 50px; */
    }
    .fruitWidthLargeC{
        width: 8% !important;
    }
    .fruitWidthsmallC{
     width: 13% !important;
    }

}