.SidebarBackground {
  background-color: #ddf1ff;
  height: 100vh;
  overflow-y: hidden;
}
.Headers {
  position: sticky;
  top: 0%;
  background-color: white;
  height: 52px;
  z-index: 99;
}
.bottomcontent{
  height: calc(100vh - 52px);
  /* border: 1px solid red; */
}
.Sidelink {
  list-style: none;
  text-decoration: none;
  color: #28303a;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Sidelink:active {
  /* padding: 0 15px; */
  border-radius: 15px;
  background-color: #0090f759;
}

.ChildSidebar {
  width: 100%;
  /* border: 1px solid black; */
  height: calc(100vh - 69px);
  overflow: auto;
}
.SidebarLink {
  width: 200px;
  overflow: hidden;
  /* padding-top: 20px; */
}
.BackArrow {
  font-size: 16px;
  font-weight: 700;
  color: #28303a;
}

.Sidebar {
  padding: 0;
}
.IconSidebar1 {
  background-color: #41a2e7;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.IconSidebar2 {
  background-color: rgb(224, 113, 79);
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.IconSidebar3 {
  background-color: rgb(134, 89, 238);
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.IconSidebar4 {
  background-color: rgb(236, 121, 178);
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.IconSidebar5 {
  background-color: rgb(121, 236, 150);
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.Togglebar{
  display: none;
}
.Menuicon{
  color: rgb(0, 0, 0) !important;
  background-color: transparent !important;
  padding: 5px;
  font-size: 18px;
  
}

@media only screen and (max-width: 500px) {
  .Sidelink {
    list-style: none;
    text-decoration: none;
    color: #28303a;
    font-size: 14px;
    font-weight: 500;
  }

}
@media only screen and (max-width: 450px) {
.Togglebar{
  display: inline;
  border: 1px solid black;
  border-radius: 5px;

}
}
