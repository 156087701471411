.loader {
    width: 100%;
    height: 100vh;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}


.gameContainer1 {
    width: 100%;
    height: 100vh;
    background: url('/public/images/coloMatching2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.extra {
    height: 10vh;
}

.playGame {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.playBtn {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.playText {
    font-size: 3rem;
    margin-left: 25px;
    font-weight: 700;
}

.top {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: space-between;
    align-items: start;
    /* border: 1px solid red; */
}

.middle {
    width: 100%;
    height: 35vh;
    /* border: 1px solid red; */
    /* padding-top: 4%; */
}

.bottom {
    width: 100%;
    height: 25vh;
    /* border: 1px solid red; */
}

.bucketContainer {
    width: 60%;
    height: 100%;
    margin: 0 auto;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.bucket {
    width: 30%;
    height: 100%;
    /* border: 1px solid red; */
    position: relative;
}

.baloon {
    position: absolute;
    bottom: 12%;
    left: 14%;
    width: 80%;
    z-index: 1;
}

.duckContainer {
    width: 60%;
    height: 100%;
    margin: 0 auto;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.duck {
    width: 25%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: url('/public/images/yellowDuck.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center; */
}

.dropedduckImg {
    width: 40%;
    position: absolute;
    bottom: 25%;
    left: 14%;
}

.duckImg {
    width: 50%;
}




@media (max-width:740px) {
    .bucketContainer {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-between;
    }

    .duckContainer {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-between;
    }



}

@media (max-width:500px) {
    .bucketContainer {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-between;
    }

    .duckContainer {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-between;
    }

}



@media (max-height:560px) {
    .gameContainer1 {
        width: 100%;
        height: 100vh;
        background: url('/public/images/coloMatching3.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    

}
@media (max-height:500px) {
   
    .dropedduckImg {
        width: 40%;
        position: absolute;
        bottom: 30%;
        left: 5%;
    }

}

@media (max-height:435px) {
    .bucketContainer {
        width: 58%;
        height: 100%;
        margin: 0 auto;
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-between;
    }

    .duck {
        width: 25%;
        height: 100%;
        /* border: 1px solid red; */

        /* background: url('/public/images/yellowDuck.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center; */
    }

}





































.floatingDuck {
    animation-name: floatingDuck;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floatingDuck {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 3px);
    }

    100% {
        transform: translate(0, -0px);
    }
}

@-moz-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Firefox */
@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Webkit */
@-ms-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* IE */
@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Opera */

.download {
    padding: 15px 15px 15px 15px;
    text-align: center;
    margin-bottom: 4px;
    font-size: 2rem;
    font-weight: 800;
    border-radius: 5px;
    font-family: calibri;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-animation: blink normal 1.5s infinite ease-in-out;
    /* Firefox */
    -webkit-animation: blink normal 1.5s infinite ease-in-out;
    /* Webkit */
    -ms-animation: blink normal 1.5s infinite ease-in-out;
    /* IE */
    animation: blink normal 1.5s infinite ease-in-out;
    /* Opera */
}


.floating {
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0px);
    }
}