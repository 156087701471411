.main_container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    /* background-size: cover !important; */
    background-size: 100% 100% !important;
    background-position: center;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startBtnPositon {
    width: 330px;
    height: 79px;
    cursor: pointer;
}


@media screen and (max-width: 1100px){
    .startBtnPositon {
        width: 220px;
    }
}
