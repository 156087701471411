.loader_Container{
  
  width: 100%;
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;

}
/* HTML: <div class="loader"></div> */
.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 100% 0 0);
  animation: l5 2s steps(11) infinite;
}
.loader:before {
  content:"Loading..."
}
@keyframes l5 {to{clip-path: inset(0 -1ch 0 0)}}
.loader:before {
content:"Loading..."
}
.loader_Container.hidden {
    display: none;
  }
.MainTracing_container {
  background-image: url("../../../public/images/tracingBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}


.muteBtn{
  position: absolute;
  top: 25px;
  right: 140px;
  z-index: 20;

}

.loader_Container{
  
  width: 100%;
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;

}
.loader {
width: fit-content;
font-weight: bold;
font-family: monospace;
font-size: 30px;
clip-path: inset(0 100% 0 0);
animation: l5 2s steps(11) infinite;
}
.loader:before {
content:"Loading..."
}