/* Pets listing style start -------------------- */

.main_container2 {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-size: 100% 100% !important;
    background-position: center;
    background-repeat: no-repeat !important;
    padding: 0 5%;
    position: relative;
}

.choosText {
    width: 100%;
    display: flex;
    justify-content: center;
}

.choosTextImg {
    width: 40%;
}

.chooseModal {
    width: 100%;
    height: 75vh;
    border: 2px solid #004021;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.4);
    padding: 2%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill,320px);
    gap: 2.5%;
    overflow-y: auto;
}

.chooseModal::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.animalBox {
    width: 100%;
    /* height: 327px; */
}

.petImg {
    width: 100%;
    height: 264px;
    border: 1px solid #004021;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.petImage {
    width: fit-content;
}

.petName {
    width: 100%;
    height: 55px;
    border: 1px solid #004021;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.6);
    margin-top: 20px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
}

@media screen and (max-width: 1050px) {
    .main_container2 {
        padding: 0 3%;
    }

    .petImg {
        padding: 5%;
    }

    .addBtnContainer {
        width: 300px;
    }
}

@media screen and (max-width: 950px) {
    /* .chooseModal {
        grid-template-columns: 31% 31% 31%;
        gap: 2.5%;
    } */

    .petName {
        font-size: 28px;
    }
}

@media screen and (max-width: 650px) {
    .petName {
        font-size: 26px;
    }

    /* .chooseModal {
        grid-template-columns: 47% 47%;
        gap: 2.5%;
    } */
}

/* Pets listing style end -------------------- */
