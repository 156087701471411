.background_img {
  background: url("/public/images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow: hidden;
}
.backButton{

  /* height: 50.75%; */
  padding: 6px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(121, 182, 225, 1);
  border-radius: 32px ;
  border: 2px solid rgba(121, 182, 225, 1);
  color: white;

}

.close_buttons {
  background: white;
  /* color: white; */
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  font-size: large;
  /* transition-duration: 0.4s; */
}
.header_of_onboarding {
  width: 100%;
  display: flex;
  justify-content: center;
}

.heading_section_of_onboarding {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small_heading_of_header {
  width: 52px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  word-wrap: break-word;
}

.large_heading_of_header {
  width: 635px;
  font-size: 44px;
  line-height: 48px;
  font-weight: 900;
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.parent_content{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0% 10%;
}

.content_section_of_onboarding {
  display: flex;
  max-width: 660px;
  width: 100%;
  height: 100%;
  padding: 16px 0px 0px 0px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px 32px 0px 0px;
  background: #fff;
}

.headerofsignin {
  padding: 0px 16px;
  display: flex;
  width: 100%;
}

.headingofsignin {
  padding: 16px;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.backbutton {
  padding: 16px;
  cursor: pointer;
  color: black;
}

.slide_of_onboarding {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100% !important;
  padding: 16px 32px 0px 32px;
}

.corousel_of_onboarding {
  width: 100% !important;
  height: 100% !important;
}

.corousel_inner_of_onboarding {
  width: 100% !important;
  height: 100% !important;
}

.headingofslide {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.heading_of_caption {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.subheading_of_caption {
  padding: 6px 15px 6px 15px;
  border-radius: 12px;
  border: 1px solid rgba(144, 158, 166, 0.35);
  width: 100%;
  margin-bottom: 10px;
}

.heading_of_caption_for_login {
  display: flex;
  justify-content: space-between;
}

.heading_of_caption_for_login span:nth-child(2) {
  color: rgba(121, 182, 225, 1);
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.corousel_indicators {
  padding: 0% !important;
  position: absolute !important;
  bottom: 25%;
  /* margin-bottom: 10px !important; */
}

.corousel_indicators_for_signin {
  padding: 0% !important;
  position: absolute !important;
  bottom: 30%;
}

/* .login_section {
  height: 15%;
  width: 100%;
  margin-bottom: 2%;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
} */
/* .Login_Button_parent{
  padding-top: 20%;
} */
.login_section_for_login {
  /* height: 15%; */
  /* width: 100%; */
  /* margin-bottom: 2%; */
  padding-top: 20%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; */
  /* border: 2px solid red; */
}

.login_button {
  /* height: 56px; */
  padding: 6px;
  width: 100%;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  text-align: center;
  border-radius: 12px;
  padding: 7px;
  color: white !important;

  background: rgba(121, 182, 225, 1) !important;
  text-decoration: none;
  border-radius: 12px;
  border: none !important;
}
/* .login_button{
    height: 56px;
    width: 634px;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
   
    text-align: center;
    border-radius: 12px;
    padding: 7px;
    color:white !important;

    background: rgba(121, 182, 225, 1) !important;
    text-decoration: none;
    border-radius: 12px;
border: none !important;
} */
/* .nextButton{
      height: 45px !important;
    width: 100% !important;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 12px;
    padding: 7px;
    color:white;

    background: rgba(121, 182, 225, 1) !important;
    text-decoration: none;
} */
.new_user_button {
  margin-top: 8px;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;
}



.new_user_button span {
  color: rgba(121, 182, 225, 1);
}

.signin_section {
  height: 50%;
  width: 100%;
  /* margin-bottom: 2%; */
  padding-top: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.btn:hover {
  color: white !important;

  background: rgba(121, 182, 225, 1) !important;
}
.signinback_button {
  height: 45px;
  width: 45%;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  padding: 7px;
  color: rgba(121, 182, 225, 1);
  background: rgba(221, 241, 255, 1);
  text-decoration: none;
  padding: 40px;
}

.signinnext_button {
  height: 56px;
  width: 45%;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  padding: 7px;
  color: white;
  background: rgba(121, 182, 225, 1);
  text-decoration: none;
}

a {
  text-decoration: none;
}

.forgotpassword {
  cursor: pointer;
}

/*modal styles*/

.modal-dialog {
  margin-bottom: 0% !important;
}

.headingofrestorepass {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.headerofmodal {
  .headerofsignin {
    padding-left: 0%;
  }
  .backbutton {
    padding-left: 0%;
    cursor: pointer;
  }
}

.footerofmodal {
  position: relative;
  bottom:5%;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  
}
.close_button {
  width: 35%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(221, 241, 255, 1);
  border-radius: 12px;
  border: none;
}
.close_button span {
  color: rgba(121, 182, 225, 1);

  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.send_button {
  width: 35%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(121, 182, 225, 1);
  border-radius: 12px;
  border: none;
}

.send_button span {
  color: white;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.getit_button {
  background: rgba(221, 241, 255, 1);

  width: 80%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: none;
}

.getit_button span {
  color: rgba(121, 182, 225, 1);
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

@media screen and (max-width: 1280px) {
  /* .Login_Button_parent{
    position: absolute !important;
    bottom :30px !important;
    width:46% !important;
  } */
}

@media screen and (max-width: 1200px) {
  .signin_section {
    padding-top: 25%;
  }
  .login_section {
    padding-top: 20%;
  }

  .subheading_of_caption {
    margin-bottom: 20px;
  }

  .login_section_for_login {
    /* padding-top: 35%; */
  }
  /* .Login_Button_parent{
    position: absolute !important;
    bottom :30px !important;
    width:60% !important;
  } */

}

@media screen and (max-width: 850px) {
  .signin_section {
    padding-top: 55%;
  }
  .login_section {
    padding-top: 50%;
  }

  .subheading_of_caption {
    margin-bottom: 20px;
  }
  .login_section_for_login {
    /* padding-top: 40%; */
  }
  /* .Login_Button_parent{
    position: absolute !important;
    bottom :30px !important;
    width:70% !important;
  } */
}

@media screen and (max-width: 600px) {
  .large_heading_of_header {
    font-size: 30px;
  }
  .signin_section {
    padding-top: 60%;
  }
  .login_section {
    padding-top: 60%;
  }

  .subheading_of_caption {
    margin-bottom: 20px;
  }
  .login_section_for_login {
    /* padding-top: 60%; */
  }
  /* .Login_Button_parent{
    position: absolute !important;
    bottom :30px !important;
    width:65% !important;
  } */
}

@media screen and (max-width: 440px) {
  .signin_section {
    padding-top: 35%;
  }
  .login_section {
    padding-top: 33%;
  }

  .subheading_of_caption {
    margin-bottom: 13px;
  }
  .corousel_indicators {
    bottom: 20%;
  }
  .large_heading_of_header {
    font-size: 20px;
  }
  .heading_of_slide {
    text-align: center;
  }
  .login_section_for_login {
    /* padding-top: 80%; */
    /* width: 100%; */
  }
  .headingofsignin {
    font-size: 14px;
  }
  .slide_of_onboarding {
    width: 100%;
    max-height: 50vh;
    display: flex;
    justify-content: center;
    height: 100% !important;
    padding: 16px 32px 0px 32px;
  }
  /* .Login_Button_parent{
    position: absolute !important;
    bottom :30px !important;
    width:63% !important;
    overflow-y: hidden;
  } */
  .signin_text{
    /* font-size: 12px; */
  }
}
