.topbar{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    padding: 0 13px;
}
.topbar2{
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    background: rgba(61, 177, 255, 0.25);
}
.topbar3{
    width: 100%;
    height: 32px;
    background: rgba(61, 177, 255, 1);
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
}


.modalBackground{
    background: url("../../../../public/images/brainBreakBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right;
    height: 50vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
}


.innerContainer{
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */

}
.top{
    width: 100%;
    height: 13%;
    /* border: 1px solid red; */
    padding-top: 2%;
}

.subHeading{
    font-weight: 500 !important;
    text-align: center;
}
.mainHeading{
    font-weight: 900 !important;
    text-align: center;
    font-size: 44px;
}

.main{
    width: 100%;
    /* height: calc(100% - (100px + 13%)); */
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottom{
    width: 100%;
    height: 100px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.viewSentence_con{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.mainDivContainer{
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 10px;
    /* border: 1px solid green; */
    /* display: flex; */
    /* justify-content: space-between; */
    padding: 24px;
}

.SentenceData{
    height: 160px;
}

.viewSentencesub_con{
    height: calc(100vh - 152px);
    padding: 20px;
    /* border: 1px solid red; */
    overflow-y: auto;
}

.activeColor{
    color: rgb(106, 23, 184) !important;
}

.topSub{
    font-size: 8px;
    font-weight: 500;
    text-align: center;
}
.topMain{
    font-size: 20px;
    font-weight: 900;
    text-align: center;
}

.yes{
    width: 100%;
    text-align: center;
    background: green;
    padding: 17px 0;
    color: white;
    font-size: 20px;
    font-weight: 700;
    border-radius: 10px;
}
.no{
    width: 100%;
    text-align: center;
    background: red;
    padding: 17px 0;
    color: white;
    font-size: 20px;
    font-weight: 700;
    border-radius: 10px;
}

.topImg{
    width: 43px;
    height: 43px;
    position: relative;
}

.topImg img{
    border-radius: 6px;
}

.icon{
    font-size: 18px;
    position: absolute;
    top: -10px;
    right: -10px;
    color: red;
    z-index: 2;
}


/* @media only screen and (max-width: 1000px) {
   
    .topbar2{
        width: 100%;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        background: rgba(61, 177, 255, 0.25);
    }
} */