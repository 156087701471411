.mainSection {
    background-color: hsl(205, 100%, 93%);
    max-width: 100%;
    height: 100vh;

    overflow: hidden;

    .home_con {
        .first {
            height: 100vh;
            width: 200px;
            /* border: 2px solid red; */
        }

        .second {
            width: 100%;

            .card_container {
                display: flex;
                flex-wrap: wrap;
                background-color: white;
                justify-content: center;
                padding: 30px 0px;
                gap: 20px;
                height: 75vh !important;
                overflow: auto;
                border-radius: 40px;




            }

            ::-webkit-scrollbar {
                display: none;
            }

            .card_item {
                width: 160px !important;
                /* height: 120px !important; */
                padding: 5px 5px 0 5px;
                /* border: 2px solid red; */
            }
        }

        .third {
            width: 300px;
            /* border: 2px solid red; */
        }
    }


}

.roundBox {
    background-color: white;
    width: 80px;
    height: 80px;
    font-size: 14px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.boxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 13px;
}

.Cards {
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 16px;
    padding: 32px;
    border-radius: 48px;

}

.imageHeading {
    font-size: 16px;
    font-weight: 400;
    color: #28303A;

}

.CardBody {
    background-color: #E7F3FB;
    border-radius: 0 0 25px 25px;
}

.ImageCustom {
    border-radius: 25px 25px 0 0;
    width: 100% !important;
    height: 110px;
}

.spin {
    border: 10px solid white;
    border-radius: 100%;
    padding: 25px;
}

.spinHeading {
    font-size: 16px;
    color: #28303A;
    font-weight: 700;
}

.spinHeading1 {
    font-size: 16px;
    font-weight: 400;
    color: #28303A;
}

.mxplayer {
    font-size: 50px;
    color: #52abeb;
}

.ButtonYes {
    background-color: green;
    font-size: 24px;
    color: white;
    font-weight: 700;
    padding: 8px 50px;
    border: none;
    border-radius: 10px;
}

.ButtonNo {
    background-color: red;
    color: white;
    font-size: 24px;
    font-weight: 700;
    padding: 8px 50px;
    border: none;
    border-radius: 10px;
}

.timeHeading {
    font-size: 12px;
    font-weight: 700px;
    color: #79B6E1;
}

.ProfileLogo {
    width: 15%;
}

.Previous {
    background-color: white;
}

.Previous1 {
    background-color: #79B6E1;
}

.AllanIcon {
    background-color: #909EA6;
    padding: 4px 10px;
    border-radius: 24px;
    color: white;
    font-size: 16px;
    font-weight: 900;
}

.welcome {
    font-size: 16px;
    font-weight: 500;
    text-align: center !important;

}

.threedot {
    background-color: #909EA6;
    padding: 3px 10px;
    border-radius: 24px;
    color: white;
}

.ArrowIcon {
    color: white;
    font-size: 20px;
    padding: 5px 10px;
    background-color: #909EA6;
    border-radius: 24px;


}

.headLogo {
    width: 70%;
}

@media only screen and (max-width: 886px) {
    .Cards {

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        overflow-y: hidden;
    }

    .boxContainer {

        display: flex;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-items: center;
        flex-direction: row !important;
        align-items: center !important;
        gap: 40px;
        margin-bottom: 30px;

    }
}

@media only screen and (max-width: 1200px) {
    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;

        align-items: center !important;

    }
}

@media only screen and (max-width: 768px) {

    .Cards {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 12px;
        overflow-y: hidden;

    }

    .boxContainer {

        display: flex;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-items: center;
        flex-direction: row !important;
        align-items: center !important;
        margin-bottom: 30px;

    }

    .timeHeading {
        font-size: 12px;
        font-weight: 400px;
        color: #79B6E1;
    }
}

@media only screen and (max-width: 767px) {
    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;

        align-items: center !important;

    }
}

@media only screen and (max-width: 600px) {
    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;
        gap: 1px;

    }

    .roundBox {
        background-color: white;
        width: 50px;
        height: 50px;
        font-size: 10px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .ArrowIcon {
        color: white;
        font-size: 15px;
        padding: 5px 10px;
        background-color: #909EA6;
        border-radius: 24px;


    }

    .Cards {
        overflow-x: hidden;
    }

}



@media only screen and (max-width: 400px) {

    .Cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px !important;
        overflow: hidden;

    }

    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;

    }

    .roundBox {
        background-color: white;
        width: 48px;
        height: 48px;
        font-size: 10px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

}

.home_con {
    .main_grid_container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px,1fr ));
        gap: 5px;
        justify-content: space-between;
    }

    .main_grid_item {
        /* border: 2px solid red; */
        flex: 1;
        /* background-color: #3498db; */
        padding: 10px;
        text-align: center;
    }

}