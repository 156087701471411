.Content {
    background-color: white;
    border-radius: 48px 0 0 0;
    height: 100%;
    padding: 10px 40px;
    overflow: auto;
}

.ContentHeadings {
    color: #28303a;
    font-size: 24px;
    font-weight: 700;
  }