.reset{
    font-size: 16px;
    font-weight: 900;
    color: #79B6E1;
}
.editBackground{
    background-color: #79B6E1;
    height: 100vh;
}
.edithead{
    background-color: white;
}
.HomeIcon{
    color: white;
    background-color: #49aef7;
    padding: 5px;
    border-radius: 30px;
}
.editsection1{
    background-color: white;
    border-radius: 24px;
}
.Homeheading{
    color: #28303A;
    font-size: 24px;
    font-weight: 700;
    padding-left: 5px;
}
.NewFolder{
    font-size: 16px;
    font-weight: 700;
    color: #79B6E1;
    cursor: pointer;
}
.EditImage{
    width: 20%;
}
.Editprofile{
    width: 10%;
}
.ImageSlide{
    width: 80%;
    background-color: red;
    display: flex;
    justify-content: between;
}

.singleList{
    background-color:#E7F3FB;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    padding: 0 16px 0 0;
    display: flex;
    overflow: hidden;
}

.singleList img{
    width: 72px;
    height: 72px;
}
.ArrowIcon {
    color: white;
    font-size: 20px;
    padding: 3px 10px;
    background-color: #909EA6;
    border-radius: 24px;

}
.EditFolderHeading{
    font-size: 20px;
    font-weight: 900;
    color: #28303A;
    padding: 20px 0px;
}
.AbcdImg{
    border-radius: 48px;
    width: 164px;
    height: 130px;
}
.DeleteHeading{
    color: red;
    font-size: 16px;
    font-weight: 700;
    padding-top: 20px;
}
.closebutton{
    background-color: 
    #DDF1FF;
    width: 48%;
    border-radius: 
    12px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: #79B6E1;
}
.sendbutton{
    background-color: 
    #79B6E1;
    width: 48%;
    border-radius: 
    12px;
    height: 56px;
    border: none;
    font-size: 16px;
    color:white;
    font-weight: 700;
}
.Label{
    font-size: 16px;
    color: #28303A;
    font-weight: 700;
}
.headerofmodal{
    padding-bottom: 50px;
}
.buttonMaindiv{
    /* padding-top: 48px; */
}
@media (max-width:1366px){
    .buttonMaindiv{
    /* padding-top: 150px; */
    /* height: 50vh; */
}
}
@media (max-width:600px) {
    .EditFolderHeading{
        font-size: 16px;
    }
}
/* @media (max-width:432px) {
.buttonMaindiv{
    padding-top: 40px;
}
} */