/* choose single pet style start -------------------- */


.main_container2 {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-size: 100% 100% !important;
    background-position: center;
    background-repeat: no-repeat !important;
    padding: 0 5%;
    position: relative;
}


.petNameModal {
    width: 100%;
    max-width: 760px;
    margin: 0 auto;
    /* height: 64vh; */
    border: 2px solid #004021;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.4);
    padding: 2%;
}

.choosText {
    width: 100%;
    display: flex;
    justify-content: center;
}

.choosTextImg {
    width: 40%;
}


.petBox {
    width: 70%;
    margin: 0 auto;
}

.innerPetContainer {
    width: 100%;
    /* height: auto; */
    border: 3px solid #FBB03B;
    border-radius: 24px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.innerPetContainer img{
 width: 180px;
}


.TigerP {
    padding: 15%;
}

.GiraffeP {
    padding: 30% !important;
}

.DuckP {
    padding: 22%;
}

.PigP {
    padding: 22%;
}

.EagleP {
    padding: 22%;
}

.RabbitP {
    padding: 22%;
}

.DogP {
    padding: 30%;
}

.labeltext {
    font-size: 28px;
    font-weight: 600;
    color: black;
}

.gameInputContainer select {
    width: 100%;
    height: 55px;
    text-align: center;
    border-radius: 10px;
    border: 3px solid #FBB03B;
    font-size: 24px;
    font-weight: 600;
}



.addBtnContainer {
    width: 330px;
    margin: 0 auto;
    cursor: pointer;
}

@media screen and (max-width: 1050px) {
    .addBtnContainer {
        width: 280px;
    }
}


/* choose single pet style end ---------------------- */
