.AllCard {
  /* margin-top: 20px; */
  /* width: 50%; */
}

.TimetableMain {
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 70%;
  border-radius: 12px 12px 4px 4px;
  margin: auto;
}

.cardTime {
  font-size: 10px;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  background: white;
}

.cardImage {
  width: 100%;
  /* height: 70px; */
  border-radius: 12px 12px 4px 4px;
}

@media (max-width: 900px) {
  .cardTime {
    font-size: 8px;
  }

  .TimetableMain {
    display: flex;
    gap: 5px;
    flex-direction: column;
    width: 80%;
    border-radius: 12px 12px 4px 4px;
    margin: auto;
  }

}

@media (max-width: 800px) and (min-width:740px){

  .TimetableMain {
    width: 90%;
  }
}
