html {
    padding: 0;
    margin: 0
}

body {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100%;
}

.main_container {
    padding: 0;
    margin: 0;
    /* overflow-x: hidden; */
    
    height: 100vh;
    /* width: 100vw; */
    /* min-width: 100vw; */
    /* min-height: 100vh; */
    background: url(/public/images/behaviourchartbg.png);
    background-position: center;
    background-size: 100% 100%;
    background-attachment: fixed;
}

.sub_main_container {
    padding: 0% 9% 0.8% 9%;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    /* border: 3px solid red; */
}

.upper_section {
    /* border: 2px solid green; */
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Lexend;
    font-size: 3rem;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
}

.addBtnSection{
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: ; */
}

.upper_section button {
    background-color: transparent;
    outline: none;
    border: none;
    position: absolute;
    top:1%;
    left: 1%;
}

.middle_section {
    height: 71%;
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column; /* This ensures that the header and the tasks list are laid out in a column */
}

.middle_section::-webkit-scrollbar-track{
    background-color: #2e2e2e;
    border-radius: 20px;
}

.middle_section::-webkit-scrollbar-thumb {
    background-color: #c5c5c5; /* Light grey thumb */
    border-radius: 10px; /* Optional: Adds rounded corners to the thumb */
  }

  .middle_section::-webkit-scrollbar {
    width: 6px; /* Adjusts the width of the vertical scrollbar */
    height: 6px; /* Adjusts the height of the horizontal scrollbar */
  }

.lower_section_of_table {
    overflow-y: auto; /* Enables vertical scrolling */
    flex-grow: 1; /* Takes up all available space */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
    overflow-y: scroll; /* Ensuring scroll functionality is still enabled */
  
}

.lower_section_of_table::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  

  

.header_of_table{
    display: flex;
    text-align: center;
    font-size: 2rem;
    height: 80px;
}



.tasks_and_days{
    display: flex;
}

.container_for_add_task{
    text-align: center;
    display: flex;
    justify-content: center;
}
.add_task{
    background-color: #1aabd8;
    padding: 0.5rem 2.5rem;
    /* border: 2px black; */
    /* border-style:solid; */
    text-align: center;
    margin-top: 2rem;
    border-radius: 20px;
    color: white;
    font-size: 1.2rem;
}


.task_button button{
    border-radius: 8px;
    padding:0.30rem 1.25rem;
    border: none;
    color:white;
    font-size: 1.25rem;
    background: green;
}

.task_input_box{
    border:none;
    width: 100%;
    padding: 0 1rem;
}

.task_heading{
    background-color: #f0ddba;
    flex:2;
    padding:5px;
}


.saturday {
    background-color: #ef527f;
    flex: 1;
    padding: 5px;
}

.sunday {
    background-color: #fcb71e;
    flex: 1;
    padding: 5px;
}

.monday {
    background-color: #eee82e;
    flex: 1;
    padding: 5px;
}

.tuesday {
    background-color: #bdd549;
    flex: 1;
    padding: 5px;
}

.wednesday {
    background-color: #4cc1bb;
    flex: 1;
    padding: 5px;
}

.thrusday {
    background-color: #1aabd8;
    flex: 1;
    padding: 5px;
}

.friday {
    background-color: #8e569d;
    flex: 1;
    padding: 5px;
}

.comment{
    background-color: #f0ddba;
    flex:2;
    padding:5px;
}

.grade_section_for_saturday{
    background-color: white;
    color:lightgray;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

/* .grade_section_for_saturday::after{
    content: 'select';
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

} */



.lower_section {
    /* border: 2px solid yellow; */
    height: 8%;
    display: flex;
    justify-content: center;
    margin-top: 1%;
}
.buttons_holder{
    display: flex;
    gap:1.25rem;
    justify-content: center;
    align-items: flex-end;
}
.buttons_holder button{
    border-radius: 8px;
    padding:0.30rem 1.25rem;
    background-color: #379df1;
    border: none;
    color:white;
    font-size: 1.25rem;
}
.buttons_holder .left_button{
    display: flex;
    justify-content: flex-end;
}
.buttons_holder .right_button{
    display: flex;
    justify-content: flex-start;
}
.buttons_holder .task_button{
    display: flex;
    justify-content: flex-start;
}





  
@media screen and (max-width: 1200px) {
    :root{
        font-size: 14px;
    }
    .upper_section{
        font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    :root{
        font-size: 12px;
    }
    .upper_section{
        font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 900px) {
    :root{
        font-size: 10px;
    }
    .upper_section{
        font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 780px) {
    :root{
        font-size: 10px;
    }
    .upper_section{
        font-size: 2.5rem;
    }

    .middle_section{
        /* min-width: 800px; */
        overflow: auto;
    }

    .upper_section button img{
        width: 25px !important;
    }

    .header_of_table{
        min-width: 800px;
        overflow: auto;
    }
    .lower_section_of_table{
        min-width: 800px;
        overflow: auto;
    }
    .lower_section_of_table div{
        width: 100%;
    }

    
.popover_images{

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
  }


  @media screen and (max-width: 550px) {
    :root{
        font-size: 7px;
    }

    
  }

 .grade_section_for_saturday img{
    height: 90%;
    width: 90%;
 }


.popover_images{

    display: flex;
    flex-wrap: wrap;
}


.popover_images div{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
