.mathsPractice_con {
    height: 100vh;
    padding: 0px 3%;
    overflow: auto;
    background-image: url("/public/images/mathsPracticeEmojis/mathPracticeGameBg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.mathsPractice_con .back_btn {
    height: 35px;
    width: 50px;
    background-image: url("/public/images/back.png");
    background-size: contain;
    margin-top: 10px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.mathsPractice_con .numbers_con {
    font-size: 50px;
    border-radius: 51px;
    border: 8px solid #009AE1;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 0px 4px 4.9px 5px rgba(0, 0, 0, 0.25);
    height: auto;
    margin-top: 15px;
}

.emoji_img {
    width: 40px ;
    height: 40px ;
}


.choose_text{
    font-size: 25px;
    margin: 8px 0px;
    color: rgb(65, 110, 128);
    font-weight: 900;
}
.option_cards_con {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.option_cards_con .option_card {
    width: 100%;
    border-radius: 51px;
    border: 4px dashed #009AE1;
    background: rgba(217, 246, 254, 0.80);
    min-height: 200px;
    cursor: pointer;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

.box_wraper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    gap: 30px;
    align-items: center;
}


@media screen and (max-width: 1330px) {
    .emoji_img {
        width: 30px ;
        height: 30px ;
    }

    .box_wraper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        justify-content: center;
        gap: 20px;
        align-items: center;
    }
   
}

@media screen and (max-width: 1050px) {
    .emoji_img {
        width: 28px ;
        height: 28px ;
    }

    .box_wraper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr ;
        justify-content: center;
        gap: 20px;
        align-items: center;
    }
}

@media screen and (max-width: 850px) {
    .emoji_img {
        width: 25px ;
        height: 25px ;
    }

    .box_wraper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr ;
        justify-content: center;
        gap: 15px;
        align-items: center;
    }
}

@media screen and (max-width: 780px) {
    .emoji_img {
        width: 23px ;
        height: 23px ;
    }

    .box_wraper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr ;
        justify-content: center;
        gap: 13px;
        align-items: center;
    }
}