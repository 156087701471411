@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Inter&family=Lexend&family=Roboto&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Inter&family=Lexend&family=Poppins:wght@700&family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap');


/* @font-face {
  font-family: 'Mochiy Pop One';
  src: url(../public/fonts/MochiyPopOne-Regular.ttf) format('ttf');
} */

/* @font-face {
  font-family: 'Poppins';
  src: url(../public/fonts/Poppins-Bold.ttf);
  font-weight: 700 !important;
} */

/* .letter_A_canvas{
  font-family: 'Poppins' !important;
} */
html , body{
  overscroll-behavior: none !important;
 
}

.multiSelectContainer{
width: 100% !important;
}

.letter_canvas, .letter_A_canvas, canvas{
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
}

.load_font{
  font-size: 0.001px;
  font-family: 'Poppins';
  font-weight: 700 !important;
  /* visibility: hidden; */
}

.pFont{
  font-family: 'Poppins' !important;
}

* {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  overscroll-behavior: none !important;
}

.fw_500{
  font-weight: 500 !important;
}

.pointer {
  cursor: pointer;
}

.fw-700 {
  font-weight: 700 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mathsPracticeShuffle {
  animation: myAnimation 0.5s linear
}

@keyframes myAnimation {
  0% {
    transform: translateX(10px);
  }

  15% {
    transform: translateX(-10px);
  }

  30% {
    transform: translateX(10px);
  }

  45% {
    transform: translateX(-10px);
  }

  60% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }

  90% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(-10px);
  }

}


/* loader style start*/
.loader_container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

/* loader style end*/

.ArrowIcon {
  color: white;
  font-size: 20px;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 4px 0px rgba(203, 217, 224, 1) inset;
  width: 56px;
  height: 40px;
  font-size: 20px;
  background-color: #909EA6;
}


@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }
}

.shake {
  animation: shake 0.5s ease-in-out;
}

.shadow_btn {
  color: white;
  font-size: 20px;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 4px 0px rgba(203, 217, 224, 1) inset;
  height: fit-content;
  width: fit-content;
  font-size: 20px;
  background-color: #909EA6;
}

.shadow_btn1 {
  color: white;
  font-size: 20px;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset;
  height: fit-content;
  width: fit-content;
  font-size: 24px;
  background-color: rgb(214, 211, 22);
}

.dropdown-menu {
  --bs-dropdown-min-width: 100% !important;
  --bs-dropdown-border-radius: 12px !important;
}

.main_grid_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  /* gap: 5px; */
  justify-content: space-between;
}

.main_grid_container2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /* gap: 5px; */
  justify-content: space-between;
}

.main_grid_container_animation {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  /* gap: 5px; */
  justify-content: space-between;
}


.main_grid_item {
  flex: 1;
  padding: 5px;
  text-align: center;
}

.main_grid_item_animation {
  flex: 1;
  padding: 5px;
  text-align: center;
}

.color_primary {
  color: #28303A !important;
}

.btn:focus {
  outline: none !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}


@media (max-width: 1025px) {
  .main_grid_container {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}

@media only screen and (max-width: 600px) {

  /* .main_grid_container{
    grid-template-columns: repeat(auto-fill, minmax(140px,1fr ));
  } */
  .ArrowIcon {
    font-size: 16px;
    width: 40px;
    height: 30px;

  }
}

.dragging {
  opacity: 0;
}



body {
  -webkit-touch-callout: none !important;
}

/* img {
  pointer-events: !important;
}  */

.activePagination{
  color: black !important;
  background: #ddf1ff;
}










.coin {
  position: absolute;
  top: var(--coin-from-x, 94px);
  left: var(--coin-from-y, 44px);
  z-index: 100;
  opacity: 0;
}

.coin::after {
  content: "$";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  font-size: 10px;
  color: rgb(237, 196, 107);
  background: rgb(227, 162, 23);
  border: 2px solid rgb(237, 196, 107);
  border-radius: 50%;
  opacity: 0;
}

.coin--animated,
.coin--animated::after {
  animation-delay: var(--coin-delay, 0s);
  animation-duration: var(--coin-duration, 1.5s);
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

.coin--animated {
  animation-name: coin-x-axis;
  animation-timing-function: ease-in;
}

.coin--animated::after {
  animation-name: coin-y-axis-and-flip;
  animation-timing-function: ease-out;
}

@keyframes coin-x-axis {
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  to {
    left: calc(var(--coin-to-x) * 1.5);
  }
}

@keyframes coin-y-axis-and-flip {
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  to {
    transform: translateY(calc(var(--coin-to-y) * 1.5)) rotate3d(1, 1, 1, 1080deg);
  }
}


/* Apply the animation to the button */
.button-clicked {
  animation: myAnimation 0.25s ease;
}

/* Define the animation */
@keyframes myAnimation {
  0% { transform: scale(1); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1); }
}

