.Atusimimgheading{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    text-align: center;
}
.BackArrow{
    font-size: 16px ;
    font-weight: 700;
    color: #28303A;
    
}
.mainAutism{
 background-color:  #ECECE1;
 height: 100vh;
}
.verbalautism{
   
    width: 100%;
    /* padding-bottom: 50px; */
}
.voice{
    color:#000000;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    display: flex;
}
.autism{
    color:#000000;
    font-size: 44px;
    font-weight: 900;
}
.autism1{
    color:#000000;
    font-size: 20px;
    font-weight: 900;
}
.verbal{
    color:#000000;  
    font-size: 8px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    display: flex;
}
.ExtraButton{
    background-color: #909EA6;
    color: white;
    font-size: 12px;
    font-weight: 900;
    border-radius: 24px;
    border: none;
    padding: 6px 16px;
}
.Box{
    display: flex;
    flex-direction: column;
   justify-content: center;
    align-items: center;
    /* gap: 10px; */
    height: 60vh;
}
.BoxImage{
display: flex;
gap: 10px;
}
.imgsize{
    width: 100%;
}
.YesOrNoimg{
    width: 100%;
 
}
.YesOrNoimg1{
    width: 100%;
 
}
.Reset{
    font-size: 14px;
    font-weight: 900;
    color:#909EA6;
}
.HeaderProfile{
    background-color: #8cd0fd;
}
.Headcolor{
    background-color:#39aefc;
    padding: 3px;
}
.Buttonpng{
    width: 70%;
}
.Autismbox{
    width: 100%;
}
.FormField{
    background-color:  #ECECE1;
    padding-bottom:10px ;
}
.Tabinput{
    background-color: white;
    border-radius: 10px;
    margin: 0 20px;
}
.Adress{
    font-size:12px ;
    font-weight: 400;
    color: #909EA6;
}
.AddresNAme{
    font-size:15px ;
    font-weight: 400;
    color: #000000;
}
.buttonUpdate{
    width: 100%;
    background-color: #909EA6;
    color: white;
    padding: 6px 16px;
    border-radius: 24px; 
    border: none;
    font-size: 12px;
    font-weight: 900;
}