.background_img {
  background: url("/public/images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.header_of_onboarding {
  width: 100%;
  /* max-width: 635 px;
    max-height: 64 px; */
  display: flex;
  justify-content: center;
}

.heading_section_of_onboarding {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small_heading_of_header {
  width: 52px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  word-wrap: break-word;
}

.large_heading_of_header {
  width: 635px;
  font-size: 44px;
  line-height: 48px;
  font-weight: 900;
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.parent_content {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  /* align-items: end; */
  padding: 0% 10%;
  position: relative;
}

.content_section_of_onboarding {
  display: flex;
  width: 60%;
  height: 100%;
  /* padding-top: 2.5%; */
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-evenly;
  border-radius: 32px 32px 0px 0px;
  background: #fff;
}

.slide_of_onboarding {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 80%;
  padding-top: 5%;
}

.corousel_of_onboarding {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  height: 100%;
}

.corousel_inner_of_onboarding {
  width: 100% !important;
  height: 85% !important;
}

.corousel_item_of_onboarding {
  height: 100%;
  width: 100%;
}

.image_of_corousel {
  width: 100%;
  height: 50%;
  object-fit: contain;
}

.corousel_caption_of_onboarding {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.heading_of_caption {
  height: 20%;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0em;
  text-align: center;
}

.subheading_of_caption {
  height: 70%;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.corousel_indicators {
  height: 10%;

  position: static !important;
  right: 0;
  bottom: 0;
  left: 0;
  /* z-index: 2; */
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 0% !important;
  margin-bottom: 0% !important;
  margin-left: 0% !important;
  list-style: none;
}

.login_section {
  height: 15%;
  width: 100%;
  /* margin-bottom: 2%; */
  /* padding: 5%; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.login_button {
  height: 45px;
  width: 90%;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  padding: 7px;
  color: white;
  background: rgba(121, 182, 225, 1);
  text-decoration: none;
}

.new_user_button {
  margin-top: 8px;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;
}

.new_user_button span {
  color: rgba(121, 182, 225, 1);
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .small_heading_of_header {
    font-size: 16px;
    width: auto;
    margin-top: 20px;
  }
  .large_heading_of_header {
    width: 635px;
    font-size: 40px;
    margin: 0px;
  }

  .heading_of_caption {
    font-size: 24px;
  }

  .image_of_corousel {
    width: 100%;
    height: 60%;
  }

  .login_button {
    font-size: 16px;
  }

  .subheading_of_caption {
    /* overflow: scroll; */
    font-size: 16px;
  }

  .content_section_of_onboarding {
    width: 100%;
  }

  .new_user_button {
    font-size: 16px;
  }
}

@media screen and (max-width: 821px) {
  .small_heading_of_header {
    font-size: 16px;
    width: auto;
    margin-top: 30px;
  }

  .large_heading_of_header {
    font-size: 40px;
    margin-top: 10px;
  }

  .heading_of_caption {
    font-size: 24px;
  }

  .image_of_corousel {
    width: 100%;
    height: 60%;
  }

  .login_button {
    font-size: 16px;
  }

  .subheading_of_caption {
    /* overflow: scroll; */
    font-size: 16px;
    /* line-height: 25px; */
  }

  .content_section_of_onboarding {
    width: 100%;
  }

  .new_user_button {
    font-size: 16px;
  }

  .corousel_of_onboarding {
    width: 90%;
  }
}

@media screen and (max-width: 440px) {
  .small_heading_of_header {
    font-size: 16px;
    width: auto;
    margin-top: 20px;
  }
  .large_heading_of_header {
    font-size: 25px;
    margin: 0px;
  }

  .heading_of_caption {
    font-size: 20px;
  }

  .image_of_corousel {
    width: 100%;
    height: 50%;
  }

  .login_button {
    font-size: 16px;
  }

  .subheading_of_caption {
    /* overflow: scroll; */
    font-size: 16px;
  }

  .content_section_of_onboarding {
    width: 100%;
  }

  .new_user_button {
    font-size: 16px;
  }
}
