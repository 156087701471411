.flappyBird_main_con {
    background-image: url("/public/images/flappyBgImage.jpg");
    height: 100vh;
    width: 100%;
    margin: auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
}

.bird_main_con {
    /* transition: top 2s ease !important */
}

.pipe_main_con {
    position: absolute;
    background-image: url("/public/images/flappyPipe.png");
    background-size: 100px 100%;
}

.space_bar {
    width: 100px;
    height: 50px;
    position: absolute;
    bottom: 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.637);
    color: white;
    z-index: 100;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    left: calc(50% - 250px);
    box-shadow: 7px 6px 28px 1px rgba(176, 176, 176, 0.24); 
}

.space_click {
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
    background-color: rgba(0, 0, 0, 0.526);
}

.up_btn{
    position: absolute;
    top: 10%;
    left: 1%;
}
.down_btn{
    position: absolute;
    bottom: 10%;
    left: 1%;
}