/* App.css */
.zoomed-area {
  /* position: relative; */
  overflow: hidden;
  width: 250px;
  height: 200px;
  display: flex;
  border-radius: 20px;
  justify-content: center;
}

.zoomed-image {
  /* position: absolute; */
  /* top: 50px; */
  /* left: 0; */
  /* width: 300px; */
  max-width: 100%;
  max-height: 100%;
}
.image {
  width: 161px;
  border-radius: 14px;
}
.imageSmall {
  width: 62px;
  height: 60px;
  border-radius: 5px;
}
.width {
  width: 99%;
  padding: 20px;
}
.editPen {
  width: 30px;
}

.cross {
  width: 40px;
}
.addButton {
  background-color: #e17998;
  width: 11%;
  border-radius: 12px;
  height: 48px;
  border: none;
  /* font-size: 16px; */
  color: white;
  font-weight: 700;
}
.changeButton {
  background-color: gray;
  width: 11%;
  border-radius: 12px;
  height: 70px;
  border: none;
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.subtext {
  color: #e17998;
  margin-left: 30px;
}
.search {
  padding-top: 10px;
  width: 100%;
  color: gray;
}

.reloadButton{
  background-color: gray;
  width: 82px;
  border-radius: 12px;
  height: 69px;
  border: none;
  /* font-size: 16px; */
  justify-content: center;
  color: white;
  font-weight: 700;
  margin-left: 10px;
}
.reloadImage{
  transform: scaleX(-1);
  width: 50%;
}


.App {
  font-family: sans-serif;
}

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

img {
  touch-action: none;
 }