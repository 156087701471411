.adminSidebar_con .logo {
    width: 70px;
}

.adminSidebar_con .verbalStyle {
    margin-top: 8px;
    font-size: 30px;
    color: #28303A;
}

.adminSidebar_con .main_con {
    background-color: rgba(221, 241, 255, 1);
    display: flex;
    padding-top: 20px;
}

.adminSidebar_con .header {
    height: 60px;
    background-color: white;
}

.adminSidebar_con .logout {
    position: absolute;
    bottom: 20px;
}

.adminSidebar_con .left_con {
    width: 300px;
    height: calc(100vh - 80px);
    overflow: auto;
}

.adminSidebar_con .left_con .navItem {
    font-size: 20px !important;
    color: black !important;
}

.adminSidebar_con .left_con .studentIcon {
    background-color: #FC7E49;
    border-radius: 10px;
    width: 35px !important;
    display: inline-block;
    height: 35px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}



.adminSidebar_con .right_con {
    width: 100%;
    border-top-left-radius: 40px;
    background-color: white;
    height: 300px;
    overflow: auto;
    padding-left: 60px;
    height: calc(100vh - 80px);


}

@media (max-width:600px) {
    .adminSidebar_con .verbalStyle {
        margin-top: 8px;
        font-size: 20px;
        color: #28303A;

    }
}