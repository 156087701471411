.mainContainer{
    width: 100%;
    height: calc(100% - 50px);
    /* border: 1px solid red; */
    overflow: hidden;
    position: relative;
}

.topContainer {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 1% 30px;
    /* border: 1px solid red; */
}


.halfContainer{
    width: 100%;
    height: 50%;
    overflow: auto;
    /* border: 1px solid red; */
}

.dragCard {
    border: 2px dashed rgba(61, 177, 255, 1);
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(61, 177, 255, 0.21);
    color: rgba(61, 177, 255, 1);
    border-radius: 24px;
    font-size: 20px;
}

.cardsContainer{
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    flex-wrap: wrap;
}

.cardCon{
    width: auto;
}

.MainModalStyle {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0) !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}

.MainModalStyle2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.modalStyle {
    width: 50%;
    height: 50%;
}

.blueBg {
    box-shadow: 0px 0px 9.5px 15px rgba(251, 247, 160, 0.49);
}
.modalBackground{
    background: url("../../../../public/images/brainBreakBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right;
    height: 50vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.textBox {
    width: 100%;
    height: 150px;
    border-radius: 24px;
    border: 1px solid rgb(228, 224, 224);
    overflow: hidden;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ss {
    width: 100%;
    height: 150px;
    border-radius: 24px;
    border: 1px solid rgb(228, 224, 224);
    overflow: hidden;
    /* border: 1px solid red; */

    img {
        height: 100%;
    }
}