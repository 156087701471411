.loader_Container {

	width: 100%;
	height: 100vh;
	background: white;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	overflow: hidden;

}

.dropImgsize {
	width: 100px;
}

.loader {
	width: fit-content;
	font-weight: bold;
	font-family: monospace;
	font-size: 30px;
	clip-path: inset(0 100% 0 0);
	animation: l5 2s steps(11) infinite;
}

.loader:before {
	content: "Loading..."
}

.loader_Container.hidden {
	display: none;
}

@keyframes l5 {
	to {
		clip-path: inset(0 -1ch 0 0)
	}
}

.color-fill-container {
	height: 100vh;
	width: 100%;
	/* background-color: rgba(221, 241, 255, 1); */
	background-image: url("/public/images/colorFillBg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	/* border: 4px solid red; */
}

.floating {
	animation-name: floating;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	margin-left: 30px;
	margin-top: 5px;
}

@keyframes floating {
	0% {
		transform: translate(0, 0px);
	}

	50% {
		transform: translate(0, 15px);
	}

	100% {
		transform: translate(0, -0px);
	}
}

.color-fill-container ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

.color-fill-container table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* END OF CSS RESET */


/* FONTS */
.color-fill-container h1,
h2 {
	font-family: 'Fredoka One', cursive;
	font-weight: bold;
	color: black;
	text-align: center;
}

.color-fill-container h1 {
	font-size: 3rem;
	padding: 2rem;
}

.color-fill-container h2 {
	font-size: 1.5rem;
	margin-bottom: 0.5rem;
}

/* GENERAL */
.color-fill-container body {
	background-color: rgb(250, 215, 220);
}

.container-color-game {




	/* margin: 0 auto 5rem auto; */
	/* padding: 2rem 2rem 2rem 2rem; */
	/* height: calc(100vh-1px); */
	/* border: 2px solid green; */
	/* overflow: hidden; */



}

.color-group-image {
	/* width: ; */
	width: 18%;
	position: absolute;
	/* left: 100px; */
	right: 100px;
}

.color-game-cloud-image {
	width: 15%;
	position: absolute;
}

.color-fill-container svg {
	display: block;
	margin: 0 auto;
}

/* path {
	fill: red !important;
} */

/* DROPDOWN */
.color-fill-container.dropdown1 {
	position: relative;
	/* display: inline-block; */
	width: 100vw !important;
	height: 10vh;
	overflow-y: scroll;


	/* margin-bottom: 4rem; */


}

.color-fill-container .dropdown-content {
	/* display: none; */
	position: absolute;
	background-color: #f9f9f9;
	width: 100%;
	/* height: 100%; */
	box-shadow: 0 0.5rem 0.2rem 0.2rem rgb(255, 192, 203, 0.4);
	border-radius: 2rem;
	padding: 1rem;
	margin: auto;
	/* top: 0; bottom: 0;  */
	left: 0;
	right: 0;
	z-index: 1;

}

.dropdown1 .dropdown-content {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1.5rem;
}

.dropdown1 .color-game-button {
	display: block;
	margin: 0 auto;
	padding: 0.2rem 2.5rem;
	border: 0.2rem solid lightpink;
	border-radius: 2rem;
	background-color: lightpink;
	box-shadow: 0 0 0.2rem 0.2rem rgb(255, 192, 203, 0.4);
	font-size: 12px;
	width: auto;
}

.color-game-reset {
	display: block;
	margin: 0 auto;
	padding: 0.2rem 2rem;
	border: 0.2rem solid rgba(221, 241, 255, 1);
	border-radius: 2rem;
	background-color: rgba(221, 241, 255, 1);
	font-size: 12px;
	width: auto;
	/* box-shadow: 0 0 0.2rem 0.2rem rgb(255, 192, 203, 0.4); */

}

.dropdown1 .color-game-button:hover,
.dropdown1 .color-game-button:active {
	background-color: white;
}

/* TINY IMAGES */
.tiny-images div {
	border: 5px solid black;
	border-radius: 2rem;
	background-color: pink;
	transition: transform .2s;
}

.tiny-images div:hover {
	transform: scale(1.05);
	background-color: white;
	box-shadow: 0 0.2rem 0.2rem 0.2rem rgb(255, 192, 203, 0.4);
}

.color-game-main-div {
	margin-top: 30px;

	/* display: flex; */
	/* justify-content: space-around; */



}

.svg_container {
	display: flex;
	gap: 20px;
	justify-content: center;
}

.color_item {
	border-radius: 10px;
	background-color: white;
	width: 70px;
	height: 70px;
	border: 0.5px solid rgb(184, 184, 184);
	margin-bottom: 10px;
	padding: 10px;
}

.active_color_mode{
	background: #76E7E7;
	box-shadow: 0px 4px 7.6px 0px rgba(0, 0, 0, 0.25);
	border: 2px solid #4e9c9c
}

#svg-images {
	width: 800px;
	height: 60vh;
	border-radius: 30px;
	box-shadow: 0px 4px 13.1px 0px rgba(0, 0, 0, 0.15);
	background-color: white;

}

/* COLOR PALETTE */
.color-pallate-name {
	font-size: 20px;
	color: white;
	font-weight: bold;
}

#paletteBox {}

/* #palette {
	width: 100%;
	height: 10rem;
	display: grid ;
	grid-template-columns: auto auto auto auto ;
	box-sizing: border-box;
	overflow: auto;
	gap: 10px;
	
} */
#palette {
	display: flex;
	justify-content: center;
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 20px;
	flex-wrap: wrap;
}

#palette div {
	width: 50px;
	height: 50px;
	border: 3px solid white;
	box-sizing: border-box;
	border-radius: 50%;
}

#palette div:hover {
	box-shadow: inset 0 0 0 1px black;
}

/* .paletteColor{
	border-radius: 50%;
} */

#red {
	background-color: red;
}

#orange {
	background-color: orange;
}

#yellow {
	background-color: yellow;
}

#green {
	background-color: green;
}

#blue {
	background-color: blue;
}

#purple {
	background-color: purple;
}

#chocolate {
	background-color: chocolate;
}

#yellowgreen {
	background-color: yellowgreen;
}

#violet {
	background-color: violet;
}

#blueviolet {
	background-color: blueviolet;
}

#burlywood {
	background-color: burlywood;
}

#aqua {
	background-color: aqua;
}

.selected {
	box-shadow: inset 0 0 0 3px black;
}

.big-images {
	display: flex;
	justify-content: center;
	height: 100%;
}

/* MEDIA QUERIES */
/* @media (max-width: 600px) {

	.color-game-main-div{
		
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		gap: 30PX;
		
	
		
	}
	
} */

@media (min-width: 650px) {
	/* svg {
		width: 70vw;
	} */


}

@media only screen and (min-width: 700px) and (orientation: landscape) {
	/* Your landscape styles here */
}

@media screen and (max-width: 1024px) {
	/* .color-game-main-div{
		margin-top: 30px;
		/* display: flex; */
	/* flex-direction: column; */



	/* }  */
	/* #palette {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		height: 10vh;
	} */
	/* #svg-images{
		background-color: rgba(255, 255, 255, 1);
		width: auto;
		border: 8px solid #6C391E;
		height: 70vh;	
	} */
	.imageSize1 {
		width: 450px;
		height: 450px;
	}

	.imageSize2 {
		width: 450px;
		height: 450px;
	}

	.imageSize3 {
		width: 450px;
		height: 450px;
	}

	.imageSize4 {
		width: 450px;
		height: 450px;
	}

	.big-images {
		/* display: flex;
		justify-content: center;
		align-items: center; */

	}
}

@media (min-width: 899) {
	/* #palette div {
		width: 100%;
		height: 100%;
		border: 0.1rem solid black;
		box-sizing: border-box;
		border-radius: 10%;
	} */

}

@media (min-width: 900px) {
	main {
		display: grid;
		grid-template-columns: 40% 60%;
		grid-template-areas: "palette svg";
	}

	h2 {
		text-align: left;
		padding: 0 0.5rem;
	}

	.dropdown1 .dropdown-content {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1.5rem;
	}

	/* svg {
		grid-area: svg;
		width: 50vw;
	} */

	/* #paletteBox {
		grid-area: palette;
	}

	#paletteBox h2 {
		text-align: center;
	} */


}

/* @media (min-width: 1100px) {
	#palette {
		width: 20vw;
	}
} */

/* SVG ILLUSTRATOR STYLES */
.st0 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 7;
	stroke-miterlimit: 10;
}

.st00 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 7;
	stroke-miterlimit: 10;
}

.st1 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 5;
	stroke-miterlimit: 10;
}

.st2 {
	fill: #ffffff;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.st3 {
	fill: #FFFFFF !important;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.st4 {
	stroke: #000000;
	fill: #000000 !important;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.st5 {
	fill: #FFFFFF !important;
}

.ca0 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 5;
	stroke-miterlimit: 15;
}

.ca1 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 4;
	stroke-miterlimit: 10;
}

.ca2 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 3;
	stroke-miterlimit: 10;
}

.ca3 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.ca4 {
	fill: none;
	stroke: #000000;
	stroke-width: 4;
	stroke-miterlimit: 10;
}

.ca5 {
	fill: none;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.ca6 {
	display: none;
}

.ca7 {
	display: inline;
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.dr0 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 5;
	stroke-miterlimit: 10;
}

.dr1 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 4;
	stroke-miterlimit: 10;
}

.dr2 {
	fill: #FFFFFF;
}

.dr3 {
	stroke: #000000;
	fill: black !important;
	stroke-width: 2;
	stroke-miterlimit: 10;
}

.dr4 {
	fill: none;
	stroke: #000000;
	stroke-width: 2;
	stroke-miterlimit: 10;
}

.dr5 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.dr6 {
	fill: none;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.dr7 {
	fill: none;
	stroke: #000000;
	stroke-width: 3;
	stroke-miterlimit: 10;
}

.dr8 {
	fill: #FFFFFF !important;
	stroke: #000000;
	stroke-width: 3;
	stroke-miterlimit: 10;
}

.dr9 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 4;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
}

.dr10 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 2;
	stroke-miterlimit: 10;
}

.black {
	fill: black !important;
}

.white {
	fill: white !important;
}

.fl0 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 4;
	stroke-miterlimit: 10;
}

.fl1 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 8;
	stroke-miterlimit: 10;
}

.fl2 {
	display: none;
}

.fl3 {
	display: inline;
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.fl4 {
	display: inline;
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 7;
	stroke-miterlimit: 10;
}

.fl5 {
	display: inline;
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 8;
	stroke-miterlimit: 10;
}

.fl6 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 6;
	stroke-miterlimit: 10;
}

.fl7 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 9;
	stroke-miterlimit: 10;
}

.fl8 {
	fill: #FFFFFF;
	stroke: #000000;
	stroke-width: 5;
	stroke-miterlimit: 10;
}