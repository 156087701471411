
.superadminSidebar_con .logo {
    width: 70px;
}

.superadminSidebar_con .verbalStyle {
    font-size: 32px;
    color: #28303A;
}

.superadminSidebar_con .main_con {
    background-color: rgba(221, 241, 255, 1);
    display: flex;
    padding-top: 20px;
}

.superadminSidebar_con .header {
    height: 60px;
    background-color: white;
}

.superadminSidebar_con .logout {
    position: absolute;
    bottom: 20px;
}

.superadminSidebar_con .left_con {
    width: 300px;
    height: calc(100vh - 80px);
    overflow: auto;
}
    .superadminSidebar_con .left_con .navItem {
        font-size: 20px !important;
        color: black !important;
    }

    .superadminSidebar_con .left_con .navItem .studentIcon {
        background-color: #FC7E49;
        border-radius: 10px;
        width: 35px !important;
        display: inline-block;
        height: 35px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }



.superadminSidebar_con .right_con {
    width: 100%;
    border-top-left-radius: 40px;
    background-color: white;
    height: 300px;
    overflow: auto;
    padding-left: 60px;
    height: calc(100vh - 80px);


}

@media (max-width:600px) {
   .superadminSidebar_con .verbalStyle {
        margin-top: 8px;
        font-size: 20px;
        color: #28303A;

    }
}
