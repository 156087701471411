.verbalStyle {
  font-size: 32px;
  color: #28303A;
}

.loader {
  /* width: 100%;
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; */
}

/* .download {
  padding: 15px 15px 15px 15px;
  text-align: center;
  margin-bottom: 4px;
  font-size: 2rem;
  font-weight: 800;
  border-radius: 5px;
  font-family: calibri;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out;
   -webkit-animation: blink normal 1.5s infinite ease-in-out;
   -ms-animation: blink normal 1.5s infinite ease-in-out;
   animation: blink normal 1.5s infinite ease-in-out;
  
} */

.logo {
  width: 70px;
}

.MainLoanCard {
  .longCard_con {
    display: flex;
    align-items: center;
    padding: 0px 40px;

    .content_con {
      width: 100%;
      display: flex;
      align-items: center;

      .dot_img {
        width: 24px;
        height: 24px;
      }

      .content_text {
        background-color: rgba(231, 243, 251, 1);
        width: 100% !important;
        border-radius: 16px;
        margin-right: 10px;

        .text {
          font-family: Lexend;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }

        & img {
          width: 72px;
          height: 72px;
          border-radius: 16px 0px 0px 16px;
        }
      }
    }

    .actions_btn {
      /* border: 1px solid red; */

      .ArrowIcon {
        color: white;
        font-size: 20px;
        padding: 6px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 2px 2px 4px 0px rgba(203, 217, 224, 1) inset;
        width: 56px;
        height: 40px;
        font-size: 20px;
        background-color: #909ea6;
      }

      @media (max-width: 600px) {
        .ArrowIcon {
          width: 40px;
          height: 30px;
          font-size: 16px;
        }
      }
    }

    .btnCon {
      display: flex;
    }

    @media only screen and (max-width: 600px) {
      .btnCon {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .longCard_con {
    padding: 0 10px;
  }
}


.text_img {
  height: auto;
  width: 80%;
  display: inline-block;
  /* border: 1px solid green; */
  border-radius: 8px;
  padding: 10px 10px;
}


.mainCardContainer {

  .shortCard_con {
    width: 100%;
    min-height: 150px;
    border-radius: 24px;

    .text_con {
      height: 60px;
      background-color: rgba(231, 243, 251, 1);
      font-family: Lexend;
      font-size: 16px;
      border-radius: 0px 0px 24px 24px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 5px;
    }

    & img {
      width: 100%;
      height: 80px;
      border-radius: 24px 24px 0px 0px;
    }
  }

  .shortcardText_con {
    /* border: 2px solid red !important; */
    padding: 10px;
  }

}


.breadcrumb_image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.view_all {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  padding: 5px 15px;
  color: rgba(40, 48, 58, 1);
  cursor: pointer;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 10px;
}

/* [[[[{{{{{{{{{{{===============Sidebar css ====================}}}}}}}}}}}]]]]*/

/* .sidebar_con {
    background-color: rgba(221, 241, 255, 1) !important;
    display: flex;
    height: calc(100vh - 110px);
    overflow: auto;
    box-sizing: border-box;

    .left_con {
        width: 200px;
        height: calc(100vh - 90px);
        overflow: auto;
        display: flex;
        justify-content: center;
    }

    .right_con {
        width: 350px !important;
        box-sizing: border-box;

    }

    .left_con::-webkit-scrollbar {
        width: 0px !important;
    }


    .child_con {
        width: 100%;
        padding: 0px 30px;

        .main_con {
            height: calc(100vh - 156px);
            background-color: white;
            border-radius: 48px;
            overflow: auto;
        }

        .main_con::-webkit-scrollbar {
            width: 0px !important;
        }

        .child_btn_con {
            height: 76px;

            & div:first-child button {
                background-color: green !important;
                margin-right: 20px;
            }

            & div button {
                height: 56px;
                color: rgba(255, 255, 255, 1);
                width: 200px;
                border: none;
                font-size: 24px;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: center;
                border-radius: 12px;
                background-color: red;
            }

            @media (max-width:880px) {
                & button {
                    width: 100px !important;
                }
            }
        }
    }

} */

@media (max-width: 600px) {
  .sidebar_con {
    flex-direction: column;
    height: calc(100vh - 150px);
  }

  .collapse_btn {
    display: none !important;
  }
}

.top_items_bar {
  display: flex;
  background-color: rgba(221, 241, 255, 1) !important;
  justify-content: space-around;
  display: none;
}

@media (max-width: 600px) {
  .top_items_bar {
    display: flex;
    background-color: rgba(221, 241, 255, 1) !important;
    justify-content: space-around;
  }

  .left_con {
    display: none !important;
  }
}

.topBar {
  height: 80px;
  background-color: rgba(221, 241, 255, 1) !important;

  .logo {
    width: 70px;
  }
}

.roundBox {
  /* background-color: #d8ba00; */
  /* background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%); */
  /* background-color: #ff002f; */
  /* background: linear-gradient(45deg, #ff0ab1, #ff002f); */

  color: white;

  /* background: linear-gradient(106.5deg, rgba(255, 215, 185, 0.91) 23%, rgba(223, 159, 247, 0.8) 93%); */

  /* width: 84px;
  height: 84px; */
  height: 68px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  /* font-size: 17px; */
  word-wrap: break-word;
  text-align: center;
  padding: 5px;
}


@media (max-width: 600px) {
  .roundBox {
    width: 50px;
    height: 50px;
    font-size: 10px;
    text-align: center;
    margin-top: 25px;
  }
}

.second_header {
  background-color: rgba(121, 182, 225, 1);
  height: 60px;

  .top_bar_text {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }
}

.MainSideContain {
  .sidebar_main_container {
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(221, 241, 255, 1);
    overflow: auto;

    .top_bar_main_container {
      .header_first {
        height: 80px !important;
        box-sizing: border-box;
        background-color: rgba(221, 241, 255, 1) !important;

        .logo {
          width: 70px;
        }

        .shadow_btn1 {
          color: #FFFFFF;
          font-size: 20px;
          padding: 6px 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
          /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
          height: fit-content;
          width: fit-content;
          /* font-size: 24px; */
          background-color: #fac83e;
        }

        @media (max-width: 766px) {
          .shadow_btn1 {
            color: #FFFFFF;
            font-size: 14px !important;
            padding: 6px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
            /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
            /* height: ; */
            /* width: ; */
            font-size: 24px;
            background-color: #fadb3e;
          }
        }

        @media (max-width: 600px) {
          .shadow_btn1 {
            color: #FFFFFF;
            font-size: 14px;
            padding: 6px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
            /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
            height: fit-content;
            width: fit-content;
            background-color: #fadb3e;
          }

        }

        @media (max-width: 600px) {
          .shadow_btn1 {
            color: #FFFFFF;
            font-size: 10px !important;
            padding: 6px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
            /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
            height: fit-content;
            width: fit-content;
            background-color: #fadb3e;
          }
        }

      }

      .header_second {

        /* height: 120px; */
        .first_header {
          height: 60px;
          background-color: white;

          .text {
            font-family: Lexend;
            font-size: 24px;
            font-weight: 900;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;

          }
        }

        .top_img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      .playTopBar {
        width: 100%;
        height: 60px;
        /* border: 1px solid red; */
      }
    }

    .body_with_short_header {
      height: calc(100vh - 160px) !important;
    }

    .bottom_main_container {
      height: calc(100vh - 160px);
      box-sizing: border-box;
      display: flex;

      .left_container {
        width: 150px;
        box-sizing: border-box;
        /* border: 1px solid grey; */
        padding-top: 30px;
        height: 100% !important;
        overflow: auto;
        display: flex;
        justify-content: center;
      }

      .small_screen_top_items {
        display: none !important;
      }

      @media (max-width: 600px) {
        .left_container {
          display: none !important;
        }

        .small_screen_top_items {
          display: block !important;
          display: flex !important;
          justify-content: space-around !important;
          align-items: center !important;
        }
      }

      .left_container::-webkit-scrollbar {
        width: 0px !important;
      }

      .child_main_container {
        /* border:1px solid orange; */
        box-sizing: border-box;
        width: 100%;

        .content_container_with_short_header {
          height: calc(100vh - 160px) !important;
        }

        .content_container {
          box-sizing: border-box;
          height: calc(100vh - 200px);
          display: flex;

          .child_bordered_container {
            width: 98%;
            overflow: auto;
            /* border:2px solid green; */
            height: 90% !important;
            margin: auto;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 48px;
          }

          .child_bordered_container::-webkit-scrollbar {
            width: 0px !important;
          }

          .child_bordered_container1 {
            width: 98%;
            overflow: auto;
            /* border:2px solid green; */
            height: 95% !important;
            margin: auto;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 48px;
          }

          .child_bordered_container1::-webkit-scrollbar {
            width: 0px !important;
          }
        }

        .child_btn_con {
          height: 80px;
          /* border: 1px solid red; */

          & div:first-child button {
            background-color: green !important;
            margin-right: 20px;
          }

          & div button {
            height: 56px;
            color: rgba(255, 255, 255, 1);
            width: 200px;
            border: none;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: center;
            border-radius: 12px;
            background-color: red;
          }

          @media (max-width: 880px) {
            & button {
              width: 100px !important;
            }
          }
        }
      }

      .right_container {
        /* width: 300px !important; */
        /* border: 1px solid pink; */
        padding-top: 30px;
        box-sizing: border-box;
        /* overflow: hidden; */
        /* border: 2px solid red; */
      }

      .timeTable {
        /* margin-top: 10px; */
      }

      @media (min-width: 600px) {
        .right_container {
          padding: 0px;
          overflow: hidden;
          width: 100%;
        }
      }

      @media (max-width: 600px) {
        .right_container {
          margin: auto;
        }

        .timeTable {
          margin-top: 40px;
        }
      }
    }

    @media (max-width: 600px) {
      .bottom_main_container {
        flex-direction: column;
      }
    }
  }
}

/* shortCard mobile app csss */
.MobileMainCardContainer {

  .shortCard_con {
    width: 100%;
    min-height: 150px;
    border-radius: 24px;

    .text_con {
      height: 60px;
      background-color: rgba(231, 243, 251, 1);
      font-family: Lexend;
      font-size: 16px;
      border-radius: 0px 0px 24px 24px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 5px;
    }

    & img {
      width: 100%;
      height: 80px;
      border-radius: 24px 24px 0px 0px;
    }
  }




  .shortcardText_con {
    /* border: 2px solid red !important; */
    padding: 10px;
  }


}


/* long card mobile app csss */

.MobileLongCardContainer {
  .longCard_con {
    display: flex;
    align-items: center;
    padding: 0px 40px;

    .content_con {
      width: 100%;
      display: flex;
      align-items: center;

      .dot_img {
        width: 24px;
        height: 24px;
      }

      .content_text {
        background-color: rgba(231, 243, 251, 1);
        width: 100% !important;
        border-radius: 16px;
        margin-right: 10px;

        .text {
          font-family: Lexend;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }

        & img {
          width: 72px;
          height: 72px;
          border-radius: 16px 0px 0px 16px;
        }
      }
    }

    .actions_btn {
      /* border: 1px solid red; */

      .ArrowIcon {
        color: white;
        font-size: 20px;
        padding: 6px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 2px 2px 4px 0px rgba(203, 217, 224, 1) inset;
        width: 56px;
        height: 40px;
        font-size: 20px;
        background-color: #909ea6;
      }

      @media (max-width: 600px) {
        .ArrowIcon {
          width: 40px;
          height: 30px;
          font-size: 16px;
        }
      }
    }

    .btnCon {
      display: flex;
    }

    @media only screen and (max-width: 600px) {
      .btnCon {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
    }
  }
}

/* sidemain mobile app cssss */

.mobileSideBarContainer {
  .sidebar_main_container {
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgba(221, 241, 255, 1);
    overflow: auto;

    .top_bar_main_container {
      .header_first {
        height: 80px !important;
        box-sizing: border-box;
        background-color: rgba(221, 241, 255, 1) !important;

        .logo {
          width: 70px;
        }

        .shadow_btn1 {
          color: #FFFFFF;
          font-size: 20px;
          padding: 6px 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

          height: fit-content;
          width: fit-content;

          background-color: #fadb3e;
        }

        @media (max-width: 766px) {
          .shadow_btn1 {
            color: #FFFFFF;
            font-size: 14px !important;
            padding: 6px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
            /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
            /* height: ; */
            /* width: ; */
            font-size: 24px;
            background-color: #fadb3e;
          }
        }

        @media (max-width: 600px) {
          .shadow_btn1 {
            color: #FFFFFF;
            font-size: 14px;
            padding: 6px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
            /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
            height: fit-content;
            width: fit-content;
            background-color: #fadb3e;
          }

        }

        @media (max-width: 600px) {
          .shadow_btn1 {
            color: #FFFFFF;
            font-size: 10px !important;
            padding: 6px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
            /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
            height: fit-content;
            width: fit-content;
            background-color: #fadb3e;
          }
        }

      }

      .header_second {

        /* height: 120px; */
        .first_header {
          height: 60px;
          background-color: white;

          .text {
            font-family: Lexend;
            font-size: 24px;
            font-weight: 900;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;

          }
        }

        .top_img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
    }

    .body_with_short_header {
      height: calc(100vh - 160px) !important;
    }

    .bottom_main_container {
      height: calc(100vh - 160px);
      box-sizing: border-box;
      display: flex;

      .left_container {
        width: 150px;
        box-sizing: border-box;
        /* border: 1px solid grey; */
        padding-top: 30px;
        height: 100% !important;
        overflow: auto;
        display: flex;
        justify-content: center;
      }

      .small_screen_top_items {
        display: none !important;
      }

      @media (max-width: 600px) {
        .left_container {
          display: none !important;
        }

        .small_screen_top_items {
          display: block !important;
          display: flex !important;
          justify-content: space-around !important;
          align-items: center !important;
        }
      }

      .left_container::-webkit-scrollbar {
        width: 0px !important;
      }

      .child_main_container {
        /* border:1px solid orange; */
        box-sizing: border-box;
        width: 100%;

        .content_container_with_short_header {
          height: calc(100vh - 160px) !important;
        }

        .content_container {
          box-sizing: border-box;
          height: calc(100vh - 200px);
          display: flex;

          .child_bordered_container {
            width: 98%;
            overflow: auto;
            /* border:2px solid green; */
            height: 90% !important;
            margin: auto;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 48px;
          }

          .child_bordered_container::-webkit-scrollbar {
            width: 0px !important;
          }

          .child_bordered_container1 {
            width: 98%;
            overflow: auto;
            /* border:2px solid green; */
            height: 95% !important;
            margin: auto;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 48px;
          }

          .child_bordered_container1::-webkit-scrollbar {
            width: 0px !important;
          }
        }

        .child_btn_con {
          height: 80px;
          /* border: 1px solid red; */

          & div:first-child button {
            background-color: green !important;
            margin-right: 20px;
          }

          & div button {
            height: 56px;
            color: rgba(255, 255, 255, 1);
            width: 200px;
            border: none;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: center;
            border-radius: 12px;
            background-color: red;
          }

          @media (max-width: 880px) {
            & button {
              width: 100px !important;
            }
          }
        }
      }

      .right_container {
        /* width: 300px !important; */
        /* border: 1px solid pink; */
        padding-top: 30px;
        box-sizing: border-box;
        /* overflow: hidden; */
        /* border: 2px solid red; */
      }

      .timeTable {
        /* margin-top: 10px; */
      }

      @media (min-width: 600px) {
        .right_container {
          padding: 0px;
          overflow: hidden;
          width: 100%;
        }
      }

      @media (max-width: 600px) {
        .right_container {
          margin: auto;
        }

        .timeTable {
          margin-top: 40px;
        }
      }
    }

    @media (max-width: 600px) {
      .bottom_main_container {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 431px) {
    .top_bar_text {

      font-size: 12px;

    }

    .view_all {
      font-size: 10px;
    }
  }
}

.main_logo {
  width: 70px;
}

.paginationContainer {
  .customPagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .customPagination ul {
    display: flex;
    list-style: none;
    justify-content: center;
    gap: 2%;
    padding: 0 !important;
  }

  .customPagination ul li {
    padding: 8px 15px;
    border-radius: 8px;
    text-align: center;
    color: rgba(152, 162, 179, 1);
  }

  .btn {
    font-size: 14px !important;
    height: 36px !important;
  }

  .btn2 {
    font-size: 14px !important;
    height: 36px !important;
    display: none !important;
  }

  .customPagination ul li:first-child,
  .customPagination ul li:last-child {
    padding: 0 !important;
    margin: 0;
  }

  .customPagination a {
    text-decoration: none !important;
  }

  .icon {
    font-size: 14px !important;
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  @media screen and (max-width: 760px) {
    .btn {
      height: 30px !important;
      font-size: 12px !important;
    }

    .btn2 {
      height: 30px !important;
      font-size: 12px !important;
    }

    .icon {
      font-size: 12px !important;
    }

    .customPagination ul li {
      padding: 5px 10px;
      border-radius: 8px;
      text-align: center;
      color: rgba(152, 162, 179, 1);
    }
  }

  @media screen and (max-width: 596px) {
    .btn {
      display: none !important;
    }

    .btn2 {
      display: block !important;
    }

    .customPagination {
      justify-content: center;
    }

    .customPagination ul li {
      padding: 4px 5px;
      font-size: 12px;
    }

  }
}