.container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.header {
    width: 100%;
    height: 60px;
}

.mainContent {
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: hidden;
    background: #e7f3fb;
    padding-top: 30px;
}

.subContainer {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 30px 30px 0 0;
    overflow-y: auto;
}

.BackArrow {
    font-size: 20px !important;
    font-weight: 800 !important;
}

.bookIcon {
    width: 35px;
    height: 35px;
    background: #ec79b2;
    border-radius: 10px;
    padding: 2px 4px;
    margin-right: 7px;
}

.ContentHomeHeading {
    color: #28303a;
    font-size: 16px;
    font-weight: 700;
}

.NewFolder {
    font-size: 16px;
    font-weight: 700;
    color: #79b6e1;
    cursor: pointer;
  }

  .singleList {
    background-color: #e7f3fb;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    padding: 0 16px 0 0;
    display: flex;
    overflow: hidden;
  }

  .mainbuttondiv{
    display: flex;
    flex-direction: row;
  }

  .btnwidth{
    width: auto;
      }

  

@media (min-width: 480px) and (max-width: 700px) {
    .ContentHomeHeading {
        color: #28303a;
        font-size: 14px;
        font-weight: 600;
    }
}

@media (max-width: 480px) {
    .mainbuttondiv{
        display: flex;
        flex-direction: column;
      }

    .btnwidth{
        width: 40px;
          }
}