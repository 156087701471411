.timer_text {
  font-family: Lexend;
  font-size: 48px;
  font-weight: 700 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  /* border: 1px solid red; */
  width: 100%;
}

.base-timer {
  /* padding:30px 0px; */
  padding-top: 30px;
  width: 100%;
  margin: auto;
  /* border: 1px solid red; */
}

/* @media (max-width:1030px){
  .base-timer{
width: 180px;
  }
}

@media (max-width:680px){
  .base-timer{
width: 150px ;
  }
} */


.img-widthv{
  width: 25px;
}

@media (max-width:800px){
  .timer_text {
    font-size: 35px;
    font-weight: 600 !important;
    line-height: 22px;
  }
  .img-widthv{
    width: 20px;
  }
}
@media (max-width:600px) {

  /* .base-timer{
width: 200px ;


  } */
  .main-timerdiv {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}