.reset {
  font-size: 16px;
  font-weight: 900;
  color: #79b6e1;
}
.editBackground {
  background-color: #ddf1ff;
  height: 100vh;
  overflow: hidden;
}
.SequenceHeader {
  width: 100%;
  height: 65px;
}
.edithead {
  background-color: white;
  position: sticky;
}
.HomeIcon {
  color: white;
  background-color: #49aef7;
  padding: 5px;
  border-radius: 30px;
}
.editsection1 {
  background-color: white;
  border-radius: 48px 48px 0 0;
  height: calc(100vh - 60px);
  overflow-y: auto;
  /* border: 2px solid red; */
}
.ContentHomeIcon {
  background-color: #e9e85a;
  padding: 5px 8px;
  /* color: white; */
  font-size: 18px;
  border-radius: 10px;
}
.Homeheading {
  color: #28303a;
  font-size: 24px;
  font-weight: 700;
  padding-left: 5px;
}
.NewFolder {
  font-size: 16px;
  font-weight: 700;
  color: #79b6e1;
  cursor: pointer;
}
.EditImage {
  width: 20%;
}
.Editprofile {
  width: 10%;
}
.ImageSlide {
  width: 80%;
  background-color: red;
  display: flex;
  justify-content: between;
}

.singleList {
  background-color: #e7f3fb;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 0 16px 0 0;
  display: flex;
  overflow: hidden;
}

.singleList img {
  width: 72px;
  height: 72px;
}
.ArrowIcon {
  color: white;
  font-size: 20px;
  padding: 3px 10px;
  background-color: #909ea6;
  border-radius: 24px;
}
.EditFolderHeading {
  font-size: 20px;
  font-weight: 900;
  color: #28303a;
  cursor: pointer;
}
.AbcdImg {
  border-radius: 48px;
  width: 164px;
  height: 130px;
  padding-bottom: 10px;
}
.DeleteHeading {
  color: red;
  font-size: 16px;
  font-weight: 700;
  padding-top: 20px;
}
.closebutton {
  background-color: #ddf1ff;
  width: 48%;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #79b6e1;
}
.sendbutton {
  background-color: #79b6e1;
  width: 48%;
  border-radius: 12px;
  height: 56px;
  border: none;
  font-size: 16px;
  color: white;
  font-weight: 700;
}
.Label {
  font-size: 16px;
  color: #28303a;
  font-weight: 700;
}
.headerofmodal {
  padding-bottom: 20px;
}

.imageBg {
  background-color: white !important;
}

.btnCon {
  display: flex;
}

.btnWidth {
  /* width: 56px; */
}

.BackArrow {
  color: #28303a;
}

.RightIcon {
  color: #79b6e1;
  font-weight: 800;
}

@media (min-width: 380px) and (max-width: 600px) {
  .btnCon {
    display: flex;
    flex-direction: column;
  }

  .btnWidth {
    width: 40px;
  }
  .EditFolderHeading{
    font-size: 16px;
  }
}

@media (max-width: 379px) {
  .btnCon {
    display: flex;
    flex-direction: column;
  }

  .btnWidth {
    width: 40px;
  }
  .ScheduleModalIconMain {
    display: flex;
    font-size: 12px;
  }

  .NewFolder {
    font-size: 14px;
  }
}

.btnMian {
  /* margin-top: 40px; */
}
.EditModalBtn{
   padding-top: 48px;
}
.buttonMaindiv{
    padding-top: 70px;
}
@media (max-width:1366px){
    .buttonMaindiv{
    /* padding-top: 220px; */
}
.EditModalBtn{
   /* padding-top: 160px; */
}
}
@media (max-width:432px) {
.buttonMaindiv{
       padding-top: 50px;
}
.EditModalBtn{
   /* padding-top: 88px; */
}
}
/* @media (max-width: 350px) {
  .btnMian {
    margin-top: 32px;
  }
} */

/* @media only screen and (min-width: 351px) and (max-width: 767px) {
  .btnMian {
    margin-top: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
}

@media (min-width: 1920px) {
} */
