.breadcrumb_image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.view_all {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  padding: 5px 15px;
  color: rgba(40, 48, 58, 1);
  cursor: pointer;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 10px;
}

.second_header {
  background-color: rgba(121, 182, 225, 1);
  height: 60px;
}

.second_header .top_bar_text {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.MainSideContain .sidebar_main_container {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(221, 241, 255, 1);
  overflow: hidden;
}

.modalContainer {
  width: 100%;
  max-height: 60vh;
  overflow: auto;
  padding: 2%;
}

.selectedIndicator {
  position: absolute;
  top: 0.1%;
  right: 1%;
  color: rgb(18, 175, 18);
}

.MainSideContain .sidebar_main_container .top_bar_main_container .header_first {
  height: 80px !important;
  box-sizing: border-box;
  background-color: rgba(221, 241, 255, 1) !important;
}

.MainSideContain .sidebar_main_container .top_bar_main_container .header_first .shadow_btn1 {
  color: #ffffff;
  font-size: 20px;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
  height: fit-content;
  width: fit-content;
  /* font-size: 24px; */
  background-color: #fac83e;
}

.shadow_btn {
  height: 45px !important;
  width: 80px !important;
}

@media (min-width: 820px) and (max-width: 1180px) {
  .shadow_btn {
    padding: 12px 26px !important;
  }
}

@media (max-width: 766px) {
  .MainSideContain .sidebar_main_container .top_bar_main_container .header_first .shadow_btn1 {
    color: #ffffff;
    font-size: 14px !important;
    padding: 6px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
    /* height: ; */
    /* width: ; */
    font-size: 24px;
    background-color: #fadb3e;
  }

  .shadow_btn {
    padding: 10px 24px !important;
  }
}

@media (max-width: 600px) {

  /* .MainSideContain
    .sidebar_main_container
    .top_bar_main_container
    .header_first
    .shadow_btn1 {
    color: #ffffff;
    font-size: 14px;
    padding: 6px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    height: fit-content;
    width: fit-content;
    background-color: #fadb3e;
  } */
  .shadow_btn {
    padding: 3px 7px !important;
    /* border: 1px solid red; */
  }

  .Image_size_mobile {
    /* border: 1px solid red; */
    height: 40px;
  }

}

@media (max-width: 740px) {
  .MainSideContain .sidebar_main_container .top_bar_main_container .header_first .shadow_btn1 {
    color: #ffffff;
    font-size: 10px !important;
    padding: 6px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
    height: fit-content;
    width: fit-content;
    background-color: #fadb3e;
  }

  .btn-widthv{
    width: 50px;
  }

}

.MainSideContain .sidebar_main_container .top_bar_main_container .header_second .first_header {
  height: 60px;
  background-color: white;
}

.MainSideContain .sidebar_main_container .top_bar_main_container .header_second .test {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.MainSideContain .sidebar_main_container .top_bar_main_container .header_second .top_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.MainSideContain .sidebar_main_container .top_bar_main_container .playTopBar {
  width: 100%;
  height: 60px;
  /* border: 1px solid red; */
}

.MainSideContain .sidebar_main_container .body_with_short_header {
  height: calc(100vh - 160px) !important;
}

.MainSideContain .sidebar_main_container .bottom_main_container {
  height: calc(100vh - 160px);
  box-sizing: border-box;
  display: flex;
}

.MainSideContain .sidebar_main_container .bottom_main_container .left_container {
  width: 150px;
  box-sizing: border-box;
  /* border: 1px solid grey; */
  padding-top: 30px;
  /* height: 100% !important; */
  height: 110% !important;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.MainSideContain .sidebar_main_container .bottom_main_container .small_screen_top_items {
  display: none !important;
}

.btn-widthv {
  width: 40px;
}

@media (max-width: 740px) {
  .MainSideContain .sidebar_main_container .bottom_main_container .left_container {
    display: none !important;
  }

  .MainSideContain .sidebar_main_container .bottom_main_container .small_screen_top_items {
    display: block !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
  }
}

.MainSideContain .sidebar_main_container .bottom_main_container .left_container::-webkit-scrollbar {
  width: 0px !important;
}

.MainSideContain .sidebar_main_container .bottom_main_container .child_main_container {
  box-sizing: border-box;
  width: 100%;
}

.MainSideContain .sidebar_main_container .bottom_main_container .content_container_with_short_header {
  height: calc(100vh - 160px) !important;
}

.MainSideContain .sidebar_main_container .bottom_main_container .right_container {
  padding-top: 30px;
  box-sizing: border-box;
  height: calc(100vh - 78px);
  overflow-x: hidden;
  overflow-y: auto;
}

.MainSideContain .sidebar_main_container .bottom_main_container .right_container::-webkit-scrollbar {
  width: 0px;
}

@media (min-width: 740px) {
  .MainSideContain .sidebar_main_container .bottom_main_container .right_container {
    padding: 0px;
    
    width: 100%;
  }

}

@media (max-width: 740px) {
  .MainSideContain .sidebar_main_container .bottom_main_container .right_container {
    margin: auto;
  }


  .MainSideContain .sidebar_main_container .bottom_main_container .timeTable {
    margin-top: 40px;
  }
}

@media (max-width: 740px) {
  .MainSideContain .sidebar_main_container .bottom_main_container {
    flex-direction: column !important;
  }
}

.MobileLongCardContainer .longCard_con {
  display: flex;
  align-items: center;
  padding: 0px 40px;
}

.MobileLongCardContainer .longCard_con .content_con {
  width: 100%;
  display: flex;
  align-items: center;
}

.MobileLongCardContainer .longCard_con .content_con .dot_img {
  width: 24px;
  height: 24px;
}

.MobileLongCardContainer .longCard_con .content_con .content_text {
  background-color: rgba(231, 243, 251, 1);
  width: 100% !important;
  border-radius: 16px;
  margin-right: 10px;
}

.MobileLongCardContainer .longCard_con .content_con .content_text .text {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.MobileLongCardContainer .longCard_con .content_con .content_text img {
  width: 72px;
  height: 72px;
  border-radius: 16px 0px 0px 16px;
}

.MobileLongCardContainer .longCard_con .actions_btn .ArrowIcon {
  color: white;
  font-size: 20px;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 4px 0px rgba(203, 217, 224, 1) inset;
  width: 56px;
  height: 40px;
  font-size: 20px;
  background-color: #909ea6;
}

@media (max-width: 600px) {
  .MobileLongCardContainer .longCard_con .actions_btn .ArrowIcon .ArrowIcon {
    width: 40px;
    height: 30px;
    font-size: 16px;
  }
}

.MobileLongCardContainer .longCard_con .btnCon {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .MobileLongCardContainer .longCard_con .btnCon {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}

.mobileSideBarContainer .sidebar_main_container {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(221, 241, 255, 1);
  overflow-x: hidden;
  overflow-y: auto;
}

.mobileSideBarContainer .sidebar_main_container .top_bar_main_container .header_first {
  height: 80px !important;
  box-sizing: border-box;
  background-color: rgba(221, 241, 255, 1) !important;
}

.mobileSideBarContainer .sidebar_main_container .top_bar_main_container .header_first .shadow_btn1 {
  color: #ffffff;
  font-size: 20px;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

  height: fit-content;
  width: fit-content;

  background-color: #fadb3e;
}

@media (max-width: 766px) {
  .mobileSideBarContainer .sidebar_main_container .top_bar_main_container .header_first .shadow_btn1 {
    color: #ffffff;
    font-size: 14px !important;
    padding: 6px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
    /* height: ; */
    /* width: ; */
    font-size: 24px;
    background-color: #fadb3e;
  }
}

@media (max-width: 600px) {
  .mobileSideBarContainer .sidebar_main_container .top_bar_main_container .header_first .shadow_btn1 {
    color: #ffffff;
    font-size: 14px;
    padding: 6px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
    height: fit-content;
    width: fit-content;
    background-color: #fadb3e;
  }
}

@media (max-width: 600px) {
  .mobileSideBarContainer .sidebar_main_container .top_bar_main_container .header_first .shadow_btn1 {
    color: #ffffff;
    font-size: 10px !important;
    padding: 6px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    /* box-shadow: 2px 2px 4px 0px rgb(247, 250, 79) inset; */
    height: fit-content;
    width: fit-content;
    background-color: #fadb3e;
  }
}

.mobileSideBarContainer .sidebar_main_container .top_bar_main_container .header_second .first_header {
  height: 60px;
  background-color: white;
}

.mobileSideBarContainer .sidebar_main_container .top_bar_main_container .header_second .first_header .text {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.mobileSideBarContainer .sidebar_main_container .top_bar_main_container .header_second .top_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.body_with_short_header {
  height: calc(100vh - 160px) !important;
}

.bottom_main_container {
  height: calc(100vh - 160px);
  box-sizing: border-box;
  display: flex;
}

.left_container {
  width: 150px;
  box-sizing: border-box;
  /* border: 1px solid grey; */
  padding-top: 30px;
  height: 100% !important;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.small_screen_top_items {
  display: none !important;
}

@media (max-width: 740px) {
  .left_container {
    display: none !important;
  }

  .small_screen_top_items {
    display: block !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
  }
}

.left_container::-webkit-scrollbar {
  width: 0px !important;
}

.child_main_container {
  /* border:1px solid orange; */
  box-sizing: border-box;
  width: 100%;
}

.content_container_with_short_header {
  height: calc(100vh - 160px) !important;
}

.content_container {
  box-sizing: border-box;
  height: calc(100vh - 200px);
  display: flex;
}

.child_bordered_container {
  width: 98%;
  overflow: auto;
  height: 90% !important;
  margin: auto;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 48px;
}

.child_bordered_container::-webkit-scrollbar {
  width: 0px !important;
}

.child_bordered_container1 {
  width: 98%;
  overflow: auto;
  /* border:2px solid green; */
  height: 95% !important;
  margin: auto;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 48px;
}

.child_bordered_container1::-webkit-scrollbar {
  width: 0px !important;
}

.child_btn_con {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}

/* .child_main_container div:first-child button {
    background-color: green !important;
    margin-right: 20px;
} */

/* **************INPORTANT**************** */
/* .child_main_container div div button {
    height: 56px;
    color: rgba(255, 255, 255, 1);
    width: 200px;
    border: none;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 12px;
    background-color: red;
} */

@media (max-width: 880px) {
  .child_main_container div:first-child button {
    width: 100px !important;
  }
}

.right_container {
  padding-top: 30px;
  box-sizing: border-box;
}



@media (min-width: 740px) {
  .right_container {
    padding: 0px;
    overflow: hidden;
    width: 100%;
  }
}

@media (max-width: 740px) {
  .right_container {
    margin: auto;
  }

  .mobileSideBarContainer .sidebar_main_container .bottom_main_container .timeTable {
    margin-top: 40px;
  }
}

@media (max-width: 740px) {
  .mobileSideBarContainer .sidebar_main_container .bottom_main_container {
    flex-direction: column;
  }
}

@media (max-width: 431px) {
  .mobileSideBarContainer .top_bar_text {
    font-size: 12px;
  }

  .mobileSideBarContainer .view_all {
    font-size: 10px;
  }
}

.main_logo {
  width: 140px;
  height: 70px;
}

.bottom_green_btn,
.bottom_button {
  height: 56px;
  color: rgba(255, 255, 255, 1);
  width: 200px;
  border: none;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  /* background-image: url("/public/images/NoBackground.png"); */
  background-color: red;
  background-size: cover;
}

.bottom_red_btn {
  background-color: green !important;
  margin-right: 20px;
  /* background-image: url("/public/images/YesBackground.png"); */
  background-size: cover;
}

.help_button {
  color: rgb(23, 22, 22);
  background-image: url("/public/images/HelpBackground.png");
  background-size: cover;
  font-size: 20px;
  font-weight: 700;
  margin-right: 20px;
  padding: 10px 50px;
  border-radius: 8px;
  border: none;
  height: 60px;
  width: auto;
  text-shadow: 0px 5px 12.3px rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
}

.task_box {
  padding: 0 10%;
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width:670px) {
  .task_box {
    padding: 0px 5px;
    gap: 10px;
    height: unset;

  }
}

@media(max-width:800px) {
  .MainSideContain .sidebar_main_container .bottom_main_container .left_container {
    width: 250px;
  }

  
  /* .task_box {
    height: 20px;
    padding: 0px;
    margin-top: 15px;
  } */
}

@media (max-width:800px){
  .btn-widthv{
    width: 20px;
  }
}
@media (max-width:590px){
  .bottom_green_btn,
.bottom_button {
  height: 56px;
  color: rgba(255, 255, 255, 1);
  width: 150px;
  border: none;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  /* background-image: url("/public/images/NoBackground.png"); */
  background-color: red;
  background-size: cover;
}
}
@media (max-width:490px){
  .bottom_green_btn,
.bottom_button {
  height: 56px;
  color: rgba(255, 255, 255, 1);
  width: 130px;
  border: none;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  /* background-image: url("/public/images/NoBackground.png"); */
  background-color: red;
  background-size: cover;
}
}

@media (max-width:450px){

  .child_btn_con{
    flex-direction: column;
    height: auto;
    align-items: unset;
    gap: 10px;
    padding: 0 5%;
  }

  .help_button {
    color: rgb(23, 22, 22);
    background-image: url("/public/images/HelpBackground.png");
    background-size: 100% 100%;
    font-size: 25px;
    font-weight: 700;
    margin-right: 0;
    padding: 15px 0;
    border-radius: 8px;
    border: none;
    height: 90px;
    width: auto;
    text-shadow: 0px 5px 12.3px rgba(0, 0, 0, 0.51);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .help_button{
    height: 100px;
    border: 1px solid red;
  } */
  .bottom_green_btn,
.bottom_button {
  height: 56px;
  color: rgba(255, 255, 255, 1);
  width: 100% ;
  border: none;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  /* background-image: url("/public/images/NoBackground.png"); */
  background-color: red;
  background-size: cover;
}
}