.mainSection {
    background-color: #DDF1FF;
    max-width: 100%;
    overflow: hidden;
    height: 100vh;

    .home_con {

        .first {
            height: 100vh;
            width: 200px;
        }

        .second {
            width: 100%;
            padding-left: 20px;

            .card_container {

                background-color: white;
                /* justify-content: center; */
                padding: 10px;
                height: 75vh !important;
                overflow: auto;
                border-radius: 40px;



            }

            ::-webkit-scrollbar {
                display: none;
            }

            .card_container1 {
                display: flex;
                flex-wrap: wrap;
                /* padding: 15px 0px; */
                gap: 10px;
            }

            .card_item {
                width: 160px !important;
                padding: 5px 5px 0 5px;
                /* height: 170px !important; */
                /* border: 2px solid red; */
            }
        }

        .third {
            width: 300px;
            /* border: 2px solid red; */
        }
    }
}

.SequencesHeader {
    background-color: white;
    height: 52px;
    position: sticky;
    top: 0%;
    padding-top: 10px;
}

.roundBox {
    background-color: white;
    width: 80px;
    height: 80px;
    font-size: 14px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.FoodProfile {
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

.boxContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.Cards1 {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 8px;
    padding: 20px;
    border-radius: 48px;
    /* height: 80vh; */

}

.Cards {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 8px;
    padding: 20px;
    border-radius: 48px;


}

.Cards2 {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 8px;
    padding: 20px;
    border-radius: 48px;
    height: 75vh;

}

.Cards3 {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 8px;
    padding: 20px;
    border-radius: 48px;
    height: 90vh;

}

.imageHeading {
    font-size: 16px;
    font-weight: 400;
    color: #28303A;

}

.CardBody {
    background-color: #E7F3FB;
    border-radius: 0 0 25px 25px;
    color: 428303A;
}

.ImageCustom {
    border-radius: 25px 25px 0 0;
    /* height: 100px; */

}

.spin {
    border: 10px solid white;
    border-radius: 100%;
    padding: 25px;
}

.spinHeading {
    font-size: 16px;
    color: #28303A;
    font-weight: 700;
}

.spinHeading1 {
    font-size: 16px;
    font-weight: 400;
    color: #28303A;
}

.mxplayer {
    font-size: 50px;
    color: #52abeb;
}

.ButtonYes {
    background-color: green;
    font-size: 24px;
    color: white;
    font-weight: 700;
    padding: 2px 50px;
    border: none;
    border-radius: 10px;
}

.ButtonNo {
    background-color: red;
    color: white;
    font-size: 24px;
    font-weight: 700;
    padding: 5px 50px;
    border: none;
    border-radius: 10px;
}

.timeHeading {
    font-size: 12px;
    font-weight: 700px;
    color: #79B6E1;
}

.ProfileLogo {
    width: 30px;
    height: 30px;
}

.Previous {
    background-color: white;
}

.Previous1 {
    background-color: #79B6E1;
}

.BackArrow {
    font-size: 16px;
    font-weight: 700;
    color: #28303A;
}

.ShapesHeading {
    font-size: 16px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
}

.ShapesHeading1 {
    font-size: 12px;
    font-weight: 700;
    color: #28303A;
    background-color: white;
    border-radius: 24px;
    cursor: pointer;
    padding: 5px 10px;
}

.HeaderProfile {
    background-color: #79B6E1;
}

.Buttonpng {
    width: 70%;
}

.Sequences {
    font-size: 24px;
    font-weight: 900;
}

.ArrowIcon {
    color: white;
    font-size: 20px;
    padding: 3px 10px;
    background-color: #909EA6;
    border-radius: 24px;


}

@media only screen and (max-width: 767px) {

    .Cards2 {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 10px;
        height: 40vh;

    }

    .Cards1 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 10px;
        height: 100vh;


    }

    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;
        gap: 1px;

    }

    .roundBox {
        background-color: white;
        width: 50px;
        height: 50px;
        font-size: 12px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .ArrowIcon {
        color: white;
        font-size: 15px;
        padding: 5px 10px;
        background-color: #909EA6;
        border-radius: 24px;


    }

    .CardBody {
        background-color: #E7F3FB;
        border-radius: 0 0 25px 25px;
    }

    .ImageCustom {
        border-radius: 25px 25px 0 0;
        /* height: 100px; */

    }
}

.ss{
    width: 100%;
    height: 150px;
    border-radius: 24px;
    border: 1px solid rgb(228, 224, 224);
    overflow: hidden;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */

    /* img{
        height: 100%;
    } */
}

.tt{
    width: 100%;
    text-align: center;
    padding: 20px;
    border: 1px solid red;
    border-radius: 10px;
    color: white;
}





@media only screen and (max-width: 800px) {

    /* .roundBox {
                background-color: white;
                width: 60px;
                height: 60px;
                font-size: 12px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;


            
            } */
    .Sequences {
        font-size: 18px;
        font-weight: 800;
    }

    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;
        gap: 5px;

    }
}

@media only screen and (max-width: 400px) {
    .Sequences {
        font-size: 20px;
        font-weight: 900;
    }

    .Cards3 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px !important;
        height: 100vh;
    }

    .Cards2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px !important;
        height: 100vh;
    }

    .Cards1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px !important;
        height: 100vh;
    }

    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;
        gap: 3px;

    }

    .roundBox {
        background-color: white;
        width: 50px;
        height: 50px;
        font-size: 11px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .ButtonYes {
        background-color: green;
        font-size: 20px;
        color: white;
        font-weight: 700;
        padding: 2px 40px;
        border: none;
        border-radius: 10px;
    }

    .ButtonNo {
        background-color: rgb(255, 7, 7);
        font-size: 20px;
        color: white;
        font-weight: 700;
        padding: 2px 40px;
        border: none;
        border-radius: 10px;
    }

}