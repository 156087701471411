.Contents {
  background-color: white;
  border-radius: 48px 0 0 0;
  height: calc(100vh - 70px);
  padding: 10px 40px;
}
.ContentHeadings {
  color: #28303a;
  font-size: 24px;
  font-weight: 700;
}
.Profilename {
  color: #28303a;
  font-size: 16px;
  font-weight: 700;
  padding-left: 30px;
}
.Label {
  font-size: 16px;
  font-weight: 700;
}
.ImageIcon {
  width: 64px;
  height: 64px;
}
.AboutHeading {
  font-size: 16px;
  font-weight: 400;
  color: #28303a;
  padding-left: 15px;
}
