.backBtnContainer{
    position: absolute;
    top: 0;
    left: 0;
}


.floating {
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0px);
    }
}


@-moz-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Firefox */
@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Webkit */
@-ms-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* IE */
@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Opera */

.download {
    padding: 15px 15px 15px 15px;
    text-align: center;
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 800;
    border-radius: 5px;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-animation: blink normal 1.5s infinite ease-in-out;
    /* Firefox */
    -webkit-animation: blink normal 1.5s infinite ease-in-out;
    /* Webkit */
    -ms-animation: blink normal 1.5s infinite ease-in-out;
    /* IE */
    animation: blink normal 1.5s infinite ease-in-out;
    /* Opera */
}




.loader{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}