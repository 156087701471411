.mainSection {
    background-color: #DDF1FF;


}
.modalBackground{
    background: url("../../../../public/images/brainBreakBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right;
    height: 50vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.SequencesHeader {
    background-color: white;
    height: 52px;
    position: sticky;
    top: 0%;
    padding-top: 10px;
}

.roundBox {
    background-color: white;
    width: 80px;
    height: 80px;
    font-size: 14px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.FoodProfile {
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

.boxContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.Cards1 {
    /* width: 100%; */
    background-color: white;
    /* border-radius: 10px; */
    /* display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 8px; */
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 40px;
    border-radius: 48px;
    /* height: 80vh; */

}

.Cards {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 8px;
    padding: 20px;
    border-radius: 48px;


}

.Cards2 {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 8px;
    padding: 20px;
    border-radius: 48px;
    height: 75vh;

}

.Cards3 {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 8px;
    padding: 20px;
    border-radius: 48px;
    height: 90vh;

}

.imageHeading {
    font-size: 16px;
    font-weight: 400;
    color: #28303A;

}

.CardBody {
    background-color: #E7F3FB;
    border-radius: 0 0 25px 25px;
    color: 428303A;
}

.ImageCustom {
    border-radius: 25px 25px 0 0;
    /* height: 100px; */

}

.spin {
    border: 10px solid white;
    border-radius: 100%;
    padding: 25px;
}

.spinHeading {
    font-size: 16px;
    color: #28303A;
    font-weight: 700;
}

.spinHeading1 {
    font-size: 16px;
    font-weight: 400;
    color: #28303A;
}

.mxplayer {
    font-size: 50px;
    color: #52abeb;
}

.ButtonYes {
    background-color: green;
    font-size: 24px;
    color: white;
    font-weight: 700;
    padding: 2px 50px;
    border: none;
    border-radius: 10px;
}

.ButtonNo {
    background-color: red;
    color: white;
    font-size: 24px;
    font-weight: 700;
    padding: 5px 50px;
    border: none;
    border-radius: 10px;
}

.timeHeading {
    font-size: 12px;
    font-weight: 700px;
    color: #79B6E1;
}

.ProfileLogo {
    width: 30px;
    height: 30px;
}

.Previous {
    background-color: white;
}

.Previous1 {
    background-color: #79B6E1;
}

.BackArrow {
    font-size: 16px;
    font-weight: 700;
    color: #28303A;
}

.ShapesHeading {
    font-size: 16px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
}

.ShapesHeading1 {
    font-size: 12px;
    font-weight: 700;
    color: #28303A;
    background-color: white;
    border-radius: 24px;
    cursor: pointer;
    padding: 5px 10px;
}

.HeaderProfile {
    background-color: #79B6E1;
}

.Buttonpng {
    width: 70%;
}

.Sequences {
    font-size: 24px;
    font-weight: 900;
}

.ArrowIcon {
    color: white;
    font-size: 20px;
    padding: 3px 10px;
    background-color: #909EA6;
    border-radius: 24px;


}

@media only screen and (max-width: 767px) {

    .Cards2 {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 10px;
        height: 40vh;

    }

    /* .Cards1{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                padding: 10px;
                height: 100vh;
              
                
            } */

    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;
        gap: 1px;

    }

    .roundBox {
        background-color: white;
        width: 50px;
        height: 50px;
        font-size: 12px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .ArrowIcon {
        color: white;
        font-size: 15px;
        padding: 5px 10px;
        background-color: #909EA6;
        border-radius: 24px;


    }

    .CardBody {
        background-color: #E7F3FB;
        border-radius: 0 0 25px 25px;
    }

    .ImageCustom {
        border-radius: 25px 25px 0 0;
        /* height: 100px; */

    }
}





@media only screen and (max-width: 800px) {
    .roundBox {
        background-color: white;
        width: 60px;
        height: 60px;
        font-size: 12px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;



    }

    .Sequences {
        font-size: 18px;
        font-weight: 800;
    }

    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;
        gap: 5px;

    }
}

@media only screen and (max-width: 400px) {
    .Sequences {
        font-size: 20px;
        font-weight: 900;
    }

    .Cards3 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px !important;
        height: 100vh;
    }

    .Cards2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px !important;
        height: 100vh;
    }

    /* .Cards1{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: 12px !important;
                height: 100vh;
            } */

    .boxContainer {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        align-items: center;
        gap: 3px;

    }

    .roundBox {
        background-color: white;
        width: 50px;
        height: 50px;
        font-size: 11px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .ButtonYes {
        background-color: green;
        font-size: 20px;
        color: white;
        font-weight: 700;
        padding: 2px 40px;
        border: none;
        border-radius: 10px;
    }

    .ButtonNo {
        background-color: rgb(255, 7, 7);
        font-size: 20px;
        color: white;
        font-weight: 700;
        padding: 2px 40px;
        border: none;
        border-radius: 10px;
    }

}

.viewSequence_con {
    height: 100%;
}

.modalStyle {
    width: 50%;
    height: 50%;
    /* background-color: red; */
    /* opacity: 0.8; */
}

.modalStyle1 {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;


    /* height:15% */
}

.MainModalStyle {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0) !important;
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: transparent !important; */
}

.MainModalStyle2 {
    width: 100%;
    height: 100%;
    /* background: rgba(0,0,0,0) !important;
    position: fixed;
    top: 0;
    left: 0;  */
    display: flex;
    justify-content: center;
}

.halfBox {
    width: 100%;
    height: 43%;
    overflow-y: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.topContainer {
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}


.halfBox::-webkit-scrollbar {
    display: none;
}

.halfBox2 {
    width: 100%;
    height: 49%;
    overflow-y: auto;
}

.topBox {
    width: 100%;
    height: calc(100% - 24px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* display: flex; */
}

.topBox::-webkit-scrollbar {
    display: none;
}


.middleHeading {
    width: 100%;
    /* border: 1px solid red; */
    text-align: center;
    font-weight: 700 !important;
    height: 24px;
}

.dragCard {
    border: 2px dashed rgba(61, 177, 255, 1);
    width: 100%;
    /* max-width: 182.5px; */
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(61, 177, 255, 0.21);
    color: rgba(61, 177, 255, 1);
    border-radius: 24px;
    font-size: 20px;
    /* margin-top: 10px; */
}

.dataCard {
    width: 100%;
    max-width: 182.5px;
    height: 152px;
    background: rgba(231, 243, 251, 1);
    border-radius: 24px;
    padding: 10px;
}

.img {
    width: 64px;
    height: 64px;
    border-radius: 12px;
}

.blueBg {
    box-shadow: 0px 0px 9.5px 15px rgba(251, 247, 160, 0.49);
}


.textBox {
    width: 100%;
    height: 150px;
    border-radius: 24px;
    border: 1px solid rgb(228, 224, 224);
    overflow: hidden;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ss {
    width: 100%;
    height: 150px;
    border-radius: 24px;
    border: 1px solid rgb(228, 224, 224);
    overflow: hidden;
    /* border: 1px solid red; */

    img {
        height: 100%;
    }
}