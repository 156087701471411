/* pet home screen style start ---------------------- */

.main_container3 {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-size: 100% 100% !important;
    background-position: center;
    background-repeat: no-repeat !important;
    padding: 0 3%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}



.coinBtnContainer {
    width: 240px;
    height: 97px;
    position: absolute;
    top: 2%;
    left: 2%;
    background: url(/public/images/adoptAPet/coinBtn.png);
    background-size: 100% 100% !important;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
}

.coinIcon {
    width: 50px;
    height: 50px;
}

.coinText {
    font-size: 40px;
    font-weight: 600;
    color: white;
}


.coinBtnContainer2 {
    width: 128px;
    height: 52px;
    background: url(/public/images/adoptAPet/coinBtn.png);
    background-size: 100% 100% !important;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
}

.coinIcon2 {
    width: 27px;
    height: 27px;
}

.coinText2 {
    font-size: 23px;
    font-weight: 600;
    color: white;

}

.homeBtnContainer {
    width: 104px;
    height: 104px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
}

.clothsBtnContainer {
    width: 104px;
    height: 104px;
    position: absolute;
    bottom: 200px;
    right: 30px;
    cursor: pointer;
}

.clothsBtnContainerT {
    width: 104px;
    height: 104px;
    position: absolute;
    bottom: 200px;
    right: 330px;
    cursor: pointer;
}

.accessoryBtnContainer {
    width: 104px;
    height: 104px;
    position: absolute;
    bottom: 40px;
    right: 30px;
    cursor: pointer;
}

.accessoryBtnContainerT {
    width: 104px;
    height: 104px;
    position: absolute;
    bottom: 40px;
    right: 330px;
    cursor: pointer;
}

.sidebarStyle {
    position: fixed;
    right: 0;
    z-index: 2;
    width: 300px;
    height: 100vh;
    background: white;
    box-shadow: inset 0px 4px 60px 0px rgba(251, 176, 59, 0.25) !important;
    border-radius: 59px 0 0 0;
    overflow: auto;
    padding: 70px 50px;
}

.clothBox {
    width: 100%;
    height: 210px;
    border: 1px solid rgba(251, 176, 59, 1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
}

.sidebarStyle::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.clothImg {
    width: 80%;
}

.asseccoriesImg {
    width: 40%;
}

.petImg {
    width: 400px;
    margin: 0 auto;
}
.petImg2 {
    width: 200px;
    margin: 0 auto;
}

.overlayBox {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: end;
    border-radius: 12px;
}

.lock {
    width: 45px;
    height: 45px;
    margin: 0 auto;
}

.QPcontainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;

}

.qContainer {
    width: 100%;
    background: rgba(251, 176, 59, 1);
    border-radius: 32px;
    border: 5.31px solid rgba(254, 148, 53, 1);
    position: relative;
    padding: 2%;
}

.pointerBox {
    width: 50px;
    height: 50px;
    transform: rotate(45deg);
    background: rgba(251, 176, 59, 1);
    border: 5.31px solid rgba(254, 148, 53, 1);
    border-width: 0 5.31px 5.31px 0;
    position: absolute;
    left: 20%;
    bottom: -28px;
}

.qText {
    color: white;
    font-size: 24.29px;
    text-shadow: 0px 3.0362205505371094px 3.0362205505371094px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Mochiy Pop One' !important;
}

.ansContainer {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 20px 2%;
}

.ansBox {
    background: rgba(254, 148, 53, 1);
    border-radius: 9.11px;
    text-align: center;
    padding: 2%;
    font-size: 24.29px;
    text-shadow: 0px 3.0362205505371094px 3.0362205505371094px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-family: 'Mochiy Pop One' !important;
    z-index: 3;
    cursor: pointer;
}

.confirmBuyModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.innerModal {
    width: 100%;
    max-width: 900px;
    /* height: 100px; */
    border: 5.31px solid rgba(254, 148, 53, 1);
    border-radius: 24px;
    /* background: rgba(255, 255, 255, 0.466); */
    background:rgba(251, 176, 59, 1);
}

.cBox {
    width: 100%;
    max-width: 500px;
    border: 5.31px solid rgba(254, 148, 53, 1);
    border-radius: 24px;
    background: white;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.cBox img{
    width: 200px;
}

.coinIcon3 {
    width: 50px;
    height: 50px;
}


.confirmBtn {
    width: 48%;
    height: 140px;
    font-size: 40px;
    text-align: center;
    border-radius: 10px;
    font-family: 'Mochiy Pop One' !important;
    color: white;
    background: url(/public/images/adoptAPet/coinBtn.png);
    background-size: 100% 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}

.cancelBtn {
    width: 48%;
    font-size: 40px;
    height: 140px;
    text-align: center;
    border-radius: 10px;
    font-family: 'Mochiy Pop One' !important;
    color: white;
    background: url(/public/images/adoptAPet/coinBtn.png);
    background-size: 100% 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .homeBtnContainer {
        width: 74px;
        height: 74px;
    }

    .clothsBtnContainer {
        width: 74px;
        height: 74px;
        bottom: 150px;
    }

    .clothsBtnContainerT {
        width: 74px;
        height: 74px;
        bottom: 150px;
    }

    .accessoryBtnContainer {
        width: 74px;
        height: 74px;
    }

    .accessoryBtnContainerT {
        width: 74px;
        height: 74px;
    }


    .QPcontainer {
        width: 100%;
        max-width: 1100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;

    }

    .qContainer {
        width: 100%;
        background: rgba(251, 176, 59, 1);
        border-radius: 32px;
        border: 5.31px solid rgba(254, 148, 53, 1);
        position: relative;
        padding: 2%;
    }

    .pointerBox {
        width: 50px;
        height: 50px;
        transform: rotate(45deg);
        background: rgba(251, 176, 59, 1);
        border: 5.31px solid rgba(254, 148, 53, 1);
        border-width: 0 5.31px 5.31px 0;
        position: absolute;
        left: 20%;
        bottom: -28px;
    }

    .qText {
        color: white;
        font-size: 20.29px;
        text-shadow: 0px 3.0362205505371094px 3.0362205505371094px 0px rgba(0, 0, 0, 0.25);
        font-family: 'Mochiy Pop One' !important;
    }

    .ansContainer {
        display: grid;
        grid-template-columns: 48% 48%;
        gap: 20px 2%;
    }

    .ansBox {
        background: rgba(254, 148, 53, 1);
        border-radius: 9.11px;
        text-align: center;
        padding: 1.5%;
        font-size: 20.29px;
        text-shadow: 0px 3.0362205505371094px 3.0362205505371094px 0px rgba(0, 0, 0, 0.25);
        color: white;
        font-family: 'Mochiy Pop One' !important;
        cursor: pointer;
    }

    .petImg {
        width: 300px;
        margin: 0 auto;
    }

    .petImg2 {
        width: 180px;
        margin: 0 auto;
    }

    .coinBtnContainer {
        width: 200px;
        height: 80px;
        position: absolute;
        top: 2%;
        left: 2%;
        background: url(/public/images/adoptAPet/coinBtn.png);
        background-size: 100% 100% !important;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        padding: 0 20px;
    }

    .coinIcon {
        width: 35px;
        height: 35px;
    }

    .coinText {
        font-size: 30px;
        font-weight: 600;
        color: white;
    }
}


@media (max-width: 1050px) {
    .petImg {
        width: 200px;
        margin: 0 auto;
    }

    .petImg2 {
        width: 150px;
        margin: 0 auto;
    }

    .QPcontainer {
        width: 100%;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;

    }

    .ansContainer {
        display: grid;
        grid-template-columns: 48% 48%;
        gap: 10px 2%;
    }

    .innerModal {
        width: 100%;
        max-width: 500px;
        /* height: 100px; */
        border: 5.31px solid rgba(254, 148, 53, 1);
        border-radius: 24px;
        /* background: rgba(255, 255, 255, 0.466); */
        background:rgba(251, 176, 59, 1);
    }
    
    .cBox {
        width: 100%;
        max-width: 300px;
        border: 5.31px solid rgba(254, 148, 53, 1);
        border-radius: 24px;
        background: white;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }
    
    .cBox img{
        width: 150px;
    }
    
    .coinIcon3 {
        width: 40px;
        height: 40px;
    }
    
    
    .confirmBtn {
        height: 100px;
        font-size: 25px;
    }
    
    .cancelBtn {
        font-size: 25px;
        height: 100px;
    }
}