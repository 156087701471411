.reset {
  font-size: 16px;
  font-weight: 900;
  color: #79b6e1;
}
.editBackground {
  background-color: #E7F3FB;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.mainheader{
  width: 100%;
  height: 70px;
  /* border: 1px solid black; */
  
}
.homeHeader{
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  /* border: 2px solid red; */
}
.newFolderDiv{
    /* border: 2px solid blue; */
    display: flex;
    justify-content: end;
  
}
.edithead {
  background-color: white;
  position: sticky;
}
.HomeIcon {
  color: white;
  background-color: #49aef7;
  padding: 5px;
  border-radius: 30px;
}
.editsection1 {
  /* border: 1px solid red; */
  background-color: white;
  border-radius: 48px 48px 0 0;
  height: calc(100vh - 70px);
  overflow-y: auto;
}
.Homeheading {
 font-family: Lexend;
font-size: 24px;
font-weight: 700;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
margin-left: 12px;
 color: #28303a;

}
.NewFolder {
  font-size: 16px;
  font-weight: 700;
  color: #79b6e1;
  cursor: pointer;
}


.EditImage {
  width: 20%;
}
.Editprofile {
  width: 10%;
}
.ImageSlide {
  width: 80%;
  background-color: red;
  display: flex;
  justify-content: between;
}

.singleList {
  background-color: #e7f3fb;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 0 16px 0 0;
  display: flex;
  overflow: hidden;
}

.singleList img {
  width: 72px;
  height: 72px;
}

.ArrowIcon {
  color: white;
  font-size: 20px;
  padding: 3px 10px;
  background-color: #909ea6;
  border-radius: 24px;
}
.EditFolderHeading {
  font-size: 20px;
  font-weight: 900;
  color: #28303a;
  padding: 20px 0px;
}
.AbcdImg {
  border-radius: 48px;
  width: 164px;
  height: 130px;
}
.DeleteHeading {
  color: red;
  font-size: 16px;
  font-weight: 700;
  padding-top: 20px;
}
.closebutton {
  background-color: #ddf1ff;
  width: 48%;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #79b6e1;
}
.sendbutton {
  background-color: #79b6e1;
  width: 48%;
  border-radius: 12px;
  height: 56px;
  border: none;
  font-size: 16px;
  color: white;
  font-weight: 700;
}
.Label {
  font-size: 16px;
  color: #28303a;
  font-weight: 700;
}
.text_img{
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.headerofmodal {
  padding-bottom: 50px;
}
.pointer {
  cursor: pointer !important;
}

.imageBg {
  background-color: white;
}
.BackArrow{
  font-family: Lexend;
font-size: 16px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color: #28303A;
}
/* .btnStyle{
width: 20px;
} */
.btnwidth{
  /* border: 2px solid red; */
}
.buttons{
  display: flex;
  /* border: 2px solid red; */


}
.mainbuttondiv{
  display: flex;
}
.buttonMaindiv{
    padding-top: 48px;
}
@media (max-width:1366px){
    .buttonMaindiv{
    /* padding-top: 100px; */
}
}

@media (max-width:600px) {
  .buttons{
    display: flex;
    flex-direction: column;
    padding: 0px ;
  }
  .btnStyle{
    /* width: ; */
    /* border: 2px solid red; */
    padding: 0;
  }
  .btnwidth{
/* border: 2px solid red; */
width: 40px;
  }
  .mainbuttondiv{
  display: flex;
  flex-direction: column;
}
}
@media (max-width:600px) {
  .EditFolderHeading{
    font-size: 16px;
  }
}
@media (max-width:432px) {
.buttonMaindiv{
    /* padding-top: 20px; */
}
}