
    .ScheduleContainer .main {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: block;
    }

    .ScheduleContainer .topbar {
        width: 100%;
        height: 57px;
        position: sticky;
        background: white;
        border-bottom: 1px solid rgb(245, 239, 239);
        top: 0;
        padding: 8px 24px;
        display: flex;
        justify-content: space-between;
    }

    .ScheduleContainer .mobile_topbar {
        width: 100%;
        height: 57px;
        position: sticky;
        background: white;
        top: 0;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid rgba(144, 158, 166, 0.35);
    }

    .ScheduleContainer .mobile_content {
        width: 100%;
        height: calc(100vh - 57px);
        background: rgba(255, 255, 255, 1);
        padding: 32px;
        /* border: 1px solid green; */
        overflow-y: auto;
    }

    .ScheduleContainer .cc {
        width: 100%;
        height: calc(100vh - 57px);
        padding: 24px;
    }

    .ScheduleContainer .content {
        border: 1px solid rgba(131, 204, 252, 1);
        /* background: rgba(221, 241, 255, 1); */
        background: white;
        /* padding: 24px; */
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        overflow: hidden;
        border-radius: 24px;
    }

    .ScheduleContainer .calenderBox {
        width: 100%;
        color: rgba(61, 177, 255, 1);
        font-size: 18px;
        border: 1px solid rgb(131, 204, 252);
        background: rgba(61, 177, 255, 0.15);
        height: 100%;
        display: block;
        position: relative;
        padding: 0;
    }

    .ScheduleContainer .backBtn i {
        font-weight: 700;
    }

    .ScheduleContainer .ii {
        color: rgba(40, 48, 58, 1) !important;
        font-weight: 700 !important;
        font-size: 18px !important;
    }

    .ScheduleContainer .heading {
        font-size: 24px;
        font-weight: 900;
    }

    .ScheduleContainer .heading_mobile {
        font-size: 20px;
        font-weight: 900;
    }

    .ScheduleContainer .left {
        width: 50%;
        border-radius: 0 48px 0 0;
        background: white;
        padding: 32px;
        height: 100%;
        display: grid;
        gap: 0 10px;
        grid-template-columns: 1fr 1fr 1fr;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .ScheduleContainer .left::-webkit-scrollbar {
        display: none;
    }

    .ScheduleContainer .right {
        width: 50%;
        padding: 32px;
        overflow-y: auto;
        /* border: 1px solid red !important; */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .ScheduleContainer .right::-webkit-scrollbar {
        display: none;
    }

    .ScheduleContainer .right_inner {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 16px;
    }

    .ScheduleContainer .card {
        width: 152px;
        height: 152px;
        max-height: 152px;
        border-radius: 24px;
        border: 2px dashed rgba(121, 182, 225, 1);
        max-width: 152px;
        overflow: hidden;
    }

    .ScheduleContainer .addCard {
        background: rgba(231, 243, 251, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .ScheduleContainer .addCard {
        color: rgba(121, 182, 225, 1);
        font-weight: 700 !important;
    }

    .ScheduleContainer .img {
        width: 100%;
        max-width: 153px;
        height: 70%;
    }

    .ScheduleContainer .bottom {
        background: white;
        width: 100%;
        height: calc(100% - 70%);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ScheduleContainer .time {
        font-weight: 700;
        text-wrap: nowrap;
    }

    .ScheduleContainer .dragCard {
        width: 100%;
        max-width: 336px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(61, 177, 255, 1);
        font-size: 18px;
        border: 2px dashed rgba(61, 177, 255, 1);
        border-radius: 12px;
        background: rgba(61, 177, 255, 0.21);
    }

    .ScheduleContainer .columnName {
        color: black !important;
        text-align: center;
        font-size: 16px;
    }

    .ScheduleContainer .container {
        max-width: 400px;
        width: 100%;
    }

    .ScheduleContainer .bottomLine {
        width: 100%;
        height: 1px;
        background: rgba(144, 158, 166, 0.35);
    }

    .ScheduleContainer .dragImg {
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }


    .ScheduleContainer .mobileViewContainer {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: none !important;
        /* border: 1px solid red; */
    }

    .ScheduleContainer .mobile_dragColumn {
        display: none !important;
        /* border: 1px solid green; */
    }

    .ScheduleContainer .dragColumn {
        display: block !important;
        width: 100%;
        /* border: 1px solid red; */
    }

    .ScheduleContainer .addDrop {
        background: rgba(61, 177, 255, 1);
        color: white;
    }

    .ScheduleContainer .draggingOver {
        background: red !important;
    }

    .ScheduleContainer .deleteIcon {
        width: 48px;
    }

    .ScheduleContainer .textAdd {
        font-size: 14px;
        color: black;
    }

    .ScheduleContainer .addSchedule {
        font-size: 3rem;
        /* border: 1px solid red; */
        color: rgba(61, 177, 255, 0.6);
        padding: 12px 0;
        background: rgba(61, 177, 255, 0.15);
    }

    .ScheduleContainer .hideFolder {
        display: none;
    }

    .ScheduleContainer .topName {
        /* width: 100%; */
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
    }

    @media only screen and (max-width: 1154px) {
        .ScheduleContainer .left {
            width: 45%;
        }

        .ScheduleContainer .right {
            width: 55%;
        }

        .ScheduleContainer .right_inner {
            grid-template-columns: auto auto auto;
        }
    }

    @media only screen and (max-width: 1050px) {
        .ScheduleContainer .addSchedule {
            padding: 9px 0;
        }
    }

    @media only screen and (max-width: 960px) {
        .ScheduleContainer .left {
            width: 50%;
        }

        .ScheduleContainer .right {
            width: 50%;
        }

        .ScheduleContainer .right_inner {
            grid-template-columns: auto auto;
        }
    }

    @media only screen and (max-width: 730px) {
        .ScheduleContainer .left {
            width: 55%;
        }

        .ScheduleContainer .right {
            width: 45%;
        }

        .ScheduleContainer .right_inner {
            grid-template-columns: auto;
        }

        .ScheduleContainer .content {

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            overflow: auto;
        }
    }

    @media only screen and (max-width: 600px) {
        .ScheduleContainer .left {
            width: 60%;
        }

        .ScheduleContainer .right {
            width: 40%;
        }

        .ScheduleContainer .right_inner {
            grid-template-columns: auto;
        }
    }

    @media only screen and (max-width: 550px) {
        .ScheduleContainer .main {
            display: none !important;
        }

        .ScheduleContainer .mobile_dragColumn {
            display: block !important;
        }

        .ScheduleContainer .hideFolder {
            display: block;
        }

        .ScheduleContainer .dragColumn {
            display: none !important;
        }

        .ScheduleContainer .mobileViewContainer {
            display: block !important;
            /* border: 1px solid red; */
        }

        .ScheduleContainer .calenderBox {
            display: none;
        }

    }









    .DropDownContainer .toggle {
        border: none !important;
    }

    .DropDownContainer .img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }

    .DropDownContainer .text {
        font-size: 17px;
        line-height: 20px;
    }

    .DropDownContainer .hr {
        width: 100%;
        height: 1px;
        background: lightgray;
    }

    .DropDownContainer .dropdownScroll {
        max-height: 300px;
        overflow-y: auto;
    }
