.Content {
  background-color: white;
  border-radius: 48px 0 0 0;
  height: 100vh;
  height: calc(100vh - 70px);
  padding: 10px 20px;
}
.ContentHeading {
  color: #28303a;
  font-size: 24px;
  font-weight: 700;
}
.ContentReset {
  color: #79b6e1;
  font-size: 16px;
  font-weight: 700;
}
.ContentPara {
  font-size: 16px;
  color: #28303a;
  font-weight: 400;
}
.ContentHome {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;

  margin: 5px 0;
}
.ContentHomeIcon1 {
  background-color: #41a2e7;
  padding: 6px 8px;
  color: white;
  font-size: 18px;

  border-radius: 10px;
}
.ContentHomeIcon2 {
  /* background-color: #28cc4c; */
  padding-right: 10px;
  color: white;
  font-size: 18px;

  border-radius: 10px;
}
.ContentHomeIcon3 {
  background-color: #e9e85a !important;
  padding: 6px 8px;
  font-weight: 900;
  color: white;
  font-size: 18px;
  border-radius: 10px;
}
.ContentHomeIcon4 {
  background-color: #7a93a8 !important;
  padding: 6px 8px;
  font-weight: 900;
  color: white;
  font-size: 18px;
  border-radius: 10px;
}
.ContentHomeHeading {
  color: #28303a;
  font-size: 16px;
  font-weight: 700;
}
.Contenticonhead:active {
  background-color: yellow;
}

.bookIcon{
  width: 35px;
  height: 35px;
  background:#ec79b2;
  border-radius: 10px;
  padding: 2px 4px;
  margin-right: 7px;
}

@media (min-width: 480px) and (max-width: 700px) {
  .ContentHomeHeading {
    color: #28303a;
    font-size: 14px;
    font-weight: 600;
  }
  .ContentPara {
    font-size: 14px;
    color: #28303a;
    font-weight: 400;
  }
  .ContentHeading {
    color: #28303a;
    font-size: 22px;
    font-weight: 600;
  }
  .ContentReset {
    color: #79b6e1;
    font-size: 14px;
    font-weight: 500;
    display: flex;
  }
}

@media (max-width: 479px) {
  .ContentHeadingMain {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }
}
